import React from "react";
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
//Icons
import { RiSubtractFill } from "react-icons/ri";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//Srcs
import "../../Style/dialogstyle.scss";

export default function PayConditionList({
  payConditionListData,
  setPayConditionListData,
}) {
  // ================================  HANDLE ADD VALUES Free  ================================
  const handleAddPayCondition = () => {
    setPayConditionListData([
      ...payConditionListData,
      { key: Date.now() + Math.random(), value: "" },
    ]);
  };
  // ================================ HANDLE UPDATE VALUES Free ==============================

  const handleUpdatePayCondition = (key, value) => {
    const newFree = payConditionListData?.map((e) => {
      if (e.key === key) e.value = value;
      return e;
    });
    setPayConditionListData(newFree);
  };
  // ========================== HANDLE REMOVE Free ============================================
  const handleDeletePayCondition = (key) => {
    const newFree = payConditionListData.filter((t) => t.key !== key);
    setPayConditionListData(newFree);
  };

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between">
        <Typography className="field-title" fontStyle="italic" mt={1}>
          Pay Conditions
        </Typography>
        <IconButton onClick={() => handleAddPayCondition()}>
          <AddBoxOutlinedIcon sx={{ color: "#0f81c2" }} />
        </IconButton>
      </Stack>
      <Divider sx={{ bgcolor: "#0f81c2 !important" }} />
      <Stack direction={"column"} spacing={1} sx={{ marginTop: "10px" }}>
        {payConditionListData?.map((t) => {
          return (
            <Stack direction="row" key={t?.key} spacing={1}>
              <TextField
                className="text-field"
                fullWidth
                size="small"
                placeholder="pay condition"
                value={t.value}
                onChange={(e) =>
                  handleUpdatePayCondition(t?.key, e?.target?.value)
                }
              />
              <IconButton onClick={() => handleDeletePayCondition(t.key)}>
                <RiSubtractFill color="red" />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
