import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  TextField,
  Grid,
  InputAdornment,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import CreateIcon from "../Assets/create-icon.svg";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import FooterPagination from "../Components/Include/FooterPagination";
import CreateContract from "../Components/Contract/CreateContract";
import ContractAction from "../Components/Contract/ContractAction";
import {
  SelectCustomer,
  SelectProject,
} from "../Components/Include/DynamicSelect";
import { GET_CONTRACT_WITH_PAGINATION } from "../Schema/Contract";
import PrintContract from "../Components/Contract/PrintContract";

export default function Contract() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [customerVal, setCustomerVal] = useState({
    id: "",
    title: "All Customer",
  });
  const [projectVal, setProjectVal] = useState({
    id: "",
    title: "All Project",
  });
  const [statusVal, setStatusVal] = useState("All");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");

  const [contractId, setContractId] = useState("");
  const [openPrint, setOpenPrint] = useState(false);
  const handleOpenPrint = (id) => {
    setContractId(id);
    setOpenPrint(true);
  };
  const handleClosePrint = () => setOpenPrint(false);

  const { refetch } = useQuery(GET_CONTRACT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      project: Boolean(projectVal?.id) ? projectVal?.id : "All Project",
      customer: Boolean(customerVal?.id) ? customerVal?.id : "All Customer",
      status: statusVal,
    },
    onCompleted: ({ getContractsPagination }) => {
      // console.log("getContractsPagination::", getContractsPagination);
      setTableData(getContractsPagination?.data);
      setPaginationData(getContractsPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [open, page, projectVal?.id, customerVal?.id, statusVal]);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    document.title = "Contract";
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <PrintContract
        open={openPrint}
        handleClose={handleClosePrint}
        contractId={contractId}
      />
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Contract</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Search contract</Typography>
            <TextField
              placeholder="search"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "#0f81c2" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by customer</Typography>
            <SelectCustomer
              selectedVal={customerVal}
              setSelectedVal={setCustomerVal}
              className={"search-field"}
              isSelected={"SELECTED"}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by project</Typography>
            <SelectProject
              selectedVal={projectVal}
              setSelectedVal={setProjectVal}
              className={"search-field"}
              isSelected={"SELECTED"}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by status</Typography>
            <FormControl fullWidth className="select-role">
              <Select
                value={statusVal}
                onChange={(e) => setStatusVal(e.target.value)}
                size="small"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2.4} xl={2.4}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreateContract
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell className="header-title-start" width="5%">
                  N°
                </TableCell>
                <TableCell className="header-title" width="15%">
                  Title
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Start date
                </TableCell>
                <TableCell className="header-title" width="10%">
                  End date
                </TableCell>
                <TableCell className="header-title" width="20%">
                  Project
                </TableCell>
                <TableCell className="header-title" width="16%">
                  Customer
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  Status
                </TableCell>
                <TableCell className="header-title-end" width="20%"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={10} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={10} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((row, index) => {
                        // console.log("row::", row);
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className="body-cell-start"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {index + paginationData?.slNo}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {row?.title}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {moment(row?.startDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {moment(row?.endDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {row?.projectName}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {row?.customerName}
                            </TableCell>
                            <TableCell
                              className="body-cell"
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              <Stack direction="row" justifyContent="center">
                                <Typography
                                  className="quotation-status"
                                  sx={{
                                    border: `1px solid ${
                                      row?.status === "In Progress"
                                        ? "#8154F8 !important"
                                        : row?.status === "Expired"
                                        ? "#EC3B47 !important"
                                        : "#068665 !important"
                                    }`,
                                    color: ` ${
                                      row?.status === "In Progress"
                                        ? "#8154F8 !important"
                                        : row?.status === "Expired"
                                        ? "#EC3B47 !important"
                                        : "#068665 !important"
                                    }`,
                                  }}
                                >
                                  {row?.status}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell className="body-cell-end" align="right">
                              <ContractAction
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
