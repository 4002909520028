import { gql } from "@apollo/client";

export const GET_TAX = gql`
  query GetTaxs {
    getTaxs {
      _id
      taxName
      tax
    }
  }
`;
export const CREATE_TAX = gql`
  mutation CreateTax($taxName: String!, $tax: Float!) {
    createTax(taxName: $taxName, tax: $tax) {
      status
      message
    }
  }
`;
export const UPDATE_TAX = gql`
  mutation UpdateTax($id: ID!, $taxName: String!, $tax: Float!) {
    updateTax(_id: $id, taxName: $taxName, tax: $tax) {
      status
      message
    }
  }
`;
export const DELETE_TAX = gql`
  mutation DeleteTax($id: ID!) {
    deleteTax(_id: $id) {
      status
      message
    }
  }
`;
