import { gql } from "@apollo/client";

export const ADD_TRACKING_PAYMENT = gql`
  mutation AddTrackingPayment($input: paymentTrackingInput!) {
    addTrackingPayment(input: $input) {
      status
      message
    }
  }
`;

export const GET_TRACKING_PAYMENT_WITH_PAGINATION = gql`
  query GetTrackingPaymentsPagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $customer: ID!
    $paymentType: String!
  ) {
    getTrackingPaymentsPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      customer: $customer
      paymentType: $paymentType
    ) {
      data {
        _id
        customerID
        customerName
        paymentName
        price
        paymentType
        expireAt
        alertFrom
        prepareBy
        remark
        mute
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;
