import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdDelete, MdModeEdit } from "react-icons/md";
//Srcs
import "../../Style/actionstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import DeleteTax from "./DeleteTax";
import UpdateTax from "./UpdateTax";

export default function TaxAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);
  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="Update" placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <DeleteTax
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
      <UpdateTax
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
