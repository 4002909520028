import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "../Assets/create-icon.svg";
//Srcs
import "../Style/pagestyle.scss";
import { currencyFormat } from "../Function/DynamicFn";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import FooterPagination from "../Components/Include/FooterPagination";
import CreateService from "../Components/Service/CreateService";
import { GET_SERVICE_WITH_PAGINATION } from "../Schema/Service";
import ServiceAction from "../Components/Service/ServiceAction";

export default function Service() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const { refetch } = useQuery(GET_SERVICE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getServicesPagination }) => {
      // console.log("getServicesPagination+", getServicesPagination);
      setTableData(getServicesPagination?.data);
      setPaginationData(getServicesPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      console.log("error", error?.message);
    },
  });
  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  useEffect(() => {
    setShowPage(page);
  }, [page, limit, keyword]);

  useEffect(() => {
    refetch();
  }, []);
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };

  useEffect(() => {
    document.title = "Service";
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link to="/setting" style={{ textDecoration: "none" }}>
            <Typography className="page-title">Setting</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Service</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">Search service</Typography>
            <TextField
              placeholder="search"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "#0f81c2" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreateService
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell className="header-title-start" width="4%">
                  N°
                </TableCell>
                <TableCell className="header-title" width="30%">
                  Service
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Service type
                </TableCell>
                <TableCell className="header-title" align="center" width="10%">
                  Unit
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Price{" "}
                </TableCell>
                <TableCell className="header-title">Remark</TableCell>
                <TableCell className="header-title-end"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell className="body-cell-start">
                              {index + paginationData?.slNo}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.service}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.serviceType}
                            </TableCell>
                            <TableCell className="body-cell" align="center">
                              {row?.unit}
                            </TableCell>
                            <TableCell className="body-cell">
                              $ {currencyFormat(row?.price)}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.remark}
                            </TableCell>
                            <TableCell className="body-cell-end" align="right">
                              <ServiceAction
                                editData={row}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
