import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { SelectCustomer, SelectProject } from "../Include/DynamicSelect";
import ServiceList from "./ServiceList";
import FreeList from "./FreeList";
import { CREATE_QUOTATION } from "../../Schema/Quotation";
import { currencyFormat, rielsFormat } from "../../Function/DynamicFn";

export default function CreateQuotation({ open, handleClose, setRefetch }) {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [projectVal, setProjectVal] = useState({ id: "", title: "" });
  const [totalVal, setTotalVal] = useState(0);
  const [vatVal, setVatVal] = useState(0);

  const [freeListData, setFreeListData] = useState([
    { key: Date.now() + Math.random(), value: { id: "", title: "" } },
  ]);

  const [requiredField, setRequiredField] = useState(false);

  //============================== SERVICE LIST DATA ======================================
  const mainItem = {
    service: { id: "", title: "", price: 0 },
    tax: { id: "", title: "", price: 0 },
    qty: 1,
    subTotal: 0,
    vat: 0,
  };
  const feature = [{ key: Date.now() + Math.random(), featureVal: "" }];
  const ItemServiceList = {
    key: Date.now() + Math.random(),
    mainItem,
    feature,
  };
  const [serviceListData, setServiceListData] = useState([ItemServiceList]);
  //========================= HANDLE ADD SERVICE LIST =====================================
  const hanldeAddServiceList = () =>
    setServiceListData([...serviceListData, ItemServiceList]);

  const [createQuotation] = useMutation(CREATE_QUOTATION, {
    onCompleted: ({ createQuotation }) => {
      setLoading(false);
      if (createQuotation?.status === true) {
        setAlert(true, "success", createQuotation?.message);
        handleClose();
        resetForm();
        setRefetch();
        setRequiredField(false);
        setProjectVal({ id: "", title: "" });
        setServiceListData([ItemServiceList]);
        setCustomerVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createQuotation?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    project: Yup.string().required("required"),
    to: Yup.string().required("required"),
    date: Yup.date(),
    note: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      project: "",
      to: "",
      date: moment(),
      note: "",
    },

    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (serviceListData.length !== 0) {
          serviceListData.map((e) => {
            if (Boolean(e?.mainItem?.service?.id)) createStatus = true;
            else {
              createStatus = false;
              setRequiredField(true);
              setLoading(false);
              throw BreakException;
            }
          });
        }
      } catch (e) {
        setLoading(false);
        if (e !== BreakException) throw e;
      }

      if (createStatus) {
        setLoading(true);
        const items = serviceListData.map((e) => {
          const itemsDes = e?.feature
            .map((i) => {
              if (Boolean(i.featureVal)) return i.featureVal;
            })
            .filter((e) => e !== undefined);
          return {
            iteme: Boolean(e?.mainItem?.service?.id)
              ? e?.mainItem?.service?.id
              : null,
            itemsDes: itemsDes,
            qty: parseFloat(e?.mainItem?.qty),
            tax: Boolean(e?.mainItem?.tax?.id) ? e?.mainItem?.tax?.id : null,
          };
        });

        const FreeValue = freeListData
          .filter((e) => {
            if (Boolean(e.value)) return e.value.id;
          })
          .map((i) => {
            return i.value.id;
          });
        // console.log("FreeValue::", FreeValue);
        createQuotation({
          variables: {
            input: {
              project: values?.project,
              to: values?.to,
              date: moment(values?.date).format("YYYY-MM-DD"),
              note: values?.note,
              items: items,
              free: FreeValue,
              finalTotal: totalVal + vatVal,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (projectVal?.id) {
      setFieldValue("project", projectVal?.id);
    } else {
      setFieldValue("project", "");
    }
  }, [projectVal?.id]);

  useEffect(() => {
    if (customerVal?.id) {
      setFieldValue("to", customerVal?.id);
    } else {
      setFieldValue("to", "");
    }
  }, [customerVal?.id]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Create Quotation</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={5}>
                <Typography className="field-title">Select Project</Typography>
                <SelectProject
                  selectedVal={projectVal}
                  setSelectedVal={setProjectVal}
                />
                {!!errors.project && touched.project && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.project}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={3.5}>
                <Typography className="field-title">To</Typography>
                <SelectCustomer
                  selectedVal={customerVal}
                  setSelectedVal={setCustomerVal}
                />
                {!!errors.to && touched.to && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.to}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={3.5}>
                <Typography className="field-title">Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.date}
                    onChange={(e) => setFieldValue("date", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="field-title" mt={1}>
                    Service
                  </Typography>
                  <IconButton onClick={hanldeAddServiceList}>
                    <AddBoxOutlinedIcon sx={{ color: "#0f81c2" }} />
                  </IconButton>
                </Stack>
                {/* ========================= SERVIVE LIST ================================ */}
                <ServiceList
                  requiredField={requiredField}
                  serviceListData={serviceListData}
                  setServiceListData={setServiceListData}
                  setTotalVal={setTotalVal}
                  setVatVal={setVatVal}
                />
              </Grid>
              <Grid item xs={12}>
                <FreeList
                  freeListData={freeListData}
                  setFreeListData={setFreeListData}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Note</Typography>

                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="note"
                  {...getFieldProps("note")}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction={"row-reverse"} spacing={12}>
                  <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {currencyFormat(totalVal)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {currencyFormat(vatVal)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {rielsFormat(totalVal + vatVal)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row-reverse"}>
                      <Typography>Sub Total ( $ )</Typography>
                    </Stack>
                    <Stack direction={"row-reverse"}>
                      <Typography>VAT ( $ )</Typography>
                    </Stack>
                    <Stack direction={"row-reverse"}>
                      <Typography>Total Amount ( $ )</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
