import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
//Srcs
import "../Style/pagestyle.scss";
import CreateIcon from "../Assets/create-icon.svg";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import FooterPagination from "../Components/Include/FooterPagination";
import CreateInvoice from "../Components/Invoice/CreateInvoice";
import InvoiceAction from "../Components/Invoice/InvoiceAction";
import {
  SelectCustomer,
  SelectProject,
} from "../Components/Include/DynamicSelect";
import { GET_INVOICE_WITH_PAGINATION } from "../Schema/Invoice";
import PreviewInvoiceDetail from "../Components/Invoice/PreviewInvoiceDetail";

export default function Invoice() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [customerVal, setCustomerVal] = useState({
    id: "",
    title: "All Customer",
  });
  const [projectVal, setProjectVal] = useState({
    id: "",
    title: "All Project",
  });
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [payment, setPayment] = useState("All Payment");
  const [type, setType] = useState("All");

  const [invoiceId, setInvoiceId] = useState("");
  const [openPrint, setOpenPrint] = useState(false);
  const handleOpenPrint = (id) => {
    setInvoiceId(id);
    setOpenPrint(true);
  };
  const handleClosePrint = () => setOpenPrint(false);

  const { refetch } = useQuery(GET_INVOICE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      project: Boolean(projectVal?.id) ? projectVal?.id : "All Project",
      customer: Boolean(customerVal?.id) ? customerVal?.id : "All Customer",
      payment: payment,
      type: type,
    },
    onCompleted: ({ getInvoicesPagination }) => {
      setTableData(getInvoicesPagination?.data);
      setPaginationData(getInvoicesPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [open, type, payment, projectVal?.id, customerVal?.id]);

  useEffect(() => {
    document.title = "Invoice"; // Set your dynamic title here
  }, []);

  return (
    <div className="page-container">
      <PreviewInvoiceDetail
        open={openPrint}
        handleClose={handleClosePrint}
        invoiceId={invoiceId}
      />
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Invoice</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by customer</Typography>
            <SelectCustomer
              selectedVal={customerVal}
              setSelectedVal={setCustomerVal}
              className={"search-field"}
              isSelected={"SELECTED"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by project</Typography>
            <SelectProject
              selectedVal={projectVal}
              setSelectedVal={setProjectVal}
              className={"search-field"}
              isSelected={"SELECTED"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by payment</Typography>
            <FormControl className="select-role" fullWidth size="small">
              <Select
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
              >
                <MenuItem value="All Payment">All Payment</MenuItem>
                <MenuItem value="Unpaid">Unpaid</MenuItem>
                <MenuItem value="Deposit">Deposit</MenuItem>
                <MenuItem value="Complete">Complete</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="header-text">Filter by type</Typography>
            <FormControl className="select-role" fullWidth size="small">
              <Select value={type} onChange={(e) => setType(e.target.value)}>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="One Time">One Time</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2.4} xl={2.4}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>

              <CreateInvoice
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow>
                <TableCell className="header-title-start" width="5%">
                  Invoice Id
                </TableCell>
                <TableCell className="header-title" width="20%">
                  Project
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Date
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  Type
                </TableCell>
                <TableCell className="header-title" width="15%">
                  Bill to
                </TableCell>
                <TableCell className="header-title" width="15%" align="center">
                  Payment
                </TableCell>
                <TableCell className="header-title-end" width="15%"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={8} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={8} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((row, index) => {
                        // console.log("row::", row);
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell
                              className={
                                row?.void
                                  ? "body-cell-start-void"
                                  : "body-cell-start"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {row.invoiceNumber}
                            </TableCell>
                            <TableCell
                              className={
                                row?.void ? "body-cell-void" : "body-cell"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              <Typography className="project-name">
                                {row.projectName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={
                                row?.void ? "body-cell-void" : "body-cell"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {moment(row.paymentDate).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                row?.void ? "body-cell-void" : "body-cell"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              <Stack direction="row" justifyContent="center">
                                <Typography
                                  className="quotation-status"
                                  sx={{
                                    bgcolor: ` ${
                                      row?.type === "One Time"
                                        ? "#FE9904 !important"
                                        : row?.type === "Monthly"
                                        ? "#7843FE !important"
                                        : row?.type === "Yearly"
                                        ? "#02855E !important"
                                        : "#068665 !important"
                                    }`,
                                  }}
                                >
                                  {row.type}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              className={
                                row?.void ? "body-cell-void" : "body-cell"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              {row.billToCustomer}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                row?.void ? "body-cell-void" : "body-cell"
                              }
                              onClick={() => handleOpenPrint(row?._id)}
                            >
                              <Stack direction="row" justifyContent="center">
                                <Typography
                                  className="quotation-status"
                                  sx={{
                                    border: `2px solid ${
                                      row?.payment === "Unpaid"
                                        ? "#FE9904 !important"
                                        : row?.payment === "Deposit"
                                        ? "#7843FE !important"
                                        : row?.payment === "Complete"
                                        ? "#02855E !important"
                                        : "#068665 !important"
                                    }`,
                                    color: ` ${
                                      row?.payment === "Unpaid"
                                        ? "#FE9904 !important"
                                        : row?.payment === "Deposit"
                                        ? "#7843FE !important"
                                        : row?.payment === "Complete"
                                        ? "#02855E !important"
                                        : "#068665 !important"
                                    }`,
                                  }}
                                >
                                  {row?.payment}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              className={
                                row?.void
                                  ? "body-cell-end-void"
                                  : "body-cell-end"
                              }
                              align="right"
                            >
                              {row.void ? (
                                <Box height="40px" />
                              ) : (
                                <InvoiceAction
                                  editData={row}
                                  setRefetch={refetch}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
