import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "../Assets/create-icon.svg";
import {
  BiLogoLinkedin,
  BiLogoYoutube,
  BiLogoSnapchat,
  BiLogoTwitter,
  BiLogoTiktok,
  BiLogoMessenger,
} from "react-icons/bi";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { PiWhatsappLogoFill, PiWechatLogoBold } from "react-icons/pi";
import { FaInstagram } from "react-icons/fa";
import { AiTwotonePhone, AiOutlineMail } from "react-icons/ai";
//Srcs
import "../Style/pagestyle.scss";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import CreateCustomer from "../Components/Customer/CreateCustomer";
import CustomerAction from "../Components/Customer/CustomerAction";
import { GET_CUSTOMER_PAGINATION } from "../Schema/Customer";
import FooterPagination from "../Components/Include/FooterPagination";

export default function Customer() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const IconData = (icon) => {
    if (icon === "Phone number") return <AiTwotonePhone size={"18px"} />;
    if (icon === "Email") return <AiOutlineMail size={"18px"} />;
    if (icon === "Facebook") return <BiLogoFacebookCircle size={"18px"} />;
    if (icon === "YouTube") return <BiLogoYoutube size={"18px"} />;
    if (icon === "Instagram") return <FaInstagram size={"18px"} />;
    if (icon === "TikTok") return <BiLogoTiktok size={"18px"} />;
    if (icon === "Snapchat") return <BiLogoSnapchat size={"18px"} />;
    if (icon === "Twitter") return <BiLogoTwitter size={"18px"} />;
    if (icon === "LinkedIn") return <BiLogoLinkedin size={"18px"} />;
    if (icon === "WhatsApp") return <PiWhatsappLogoFill size={"18px"} />;
    if (icon === "Messenger") return <BiLogoMessenger size={"18px"} />;
    if (icon === "WeChat") return <PiWechatLogoBold size={"18px"} />;
  };
  const { refetch } = useQuery(GET_CUSTOMER_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getCustomerPagination }) => {
      // console.log("getCustomerPagination::", getCustomerPagination)
      setTableData(getCustomerPagination.data);
      setPaginationData(getCustomerPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error:::::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };

  useEffect(() => {
    document.title = "Customer";
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Customer</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">Search customer</Typography>
            <TextField
              placeholder="search"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "#0f81c2" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreateCustomer
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell className="header-title-start" sx={{ width: "4%" }}>
                N°
              </TableCell>
              <TableCell className="header-title" sx={{ width: "15%" }}>
                Latin Name
              </TableCell>
              <TableCell className="header-title" sx={{ width: "15%" }}>
                Khmer Name
              </TableCell>
              <TableCell className="header-title" sx={{ width: "25%" }}>
                Contact List
              </TableCell>
              <TableCell className="header-title" sx={{ width: "25%" }}>
                Address
              </TableCell>
              <TableCell className="header-title" sx={{ width: "10%" }}>
                Remark
              </TableCell>
              <TableCell className="header-title-end"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={7} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={7} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((item, index) => {
                        // console.log("item::", item)
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell className="body-cell-start">
                              {index + paginationData?.slNo}
                            </TableCell>
                            <TableCell className="body-cell">
                              <Stack direction="row" spacing={1}>
                                <Stack
                                  direction="column"
                                  justifyContent="center"
                                >
                                  {/* data:image/jpg;base64,${data?.profileImage?.toString()}, */}
                                  <Avatar
                                    sx={{ width: 40, height: 40 }}
                                    alt={item?.customerName}
                                    src={`data:image/jpg;base64,${item?.img}`}
                                  />
                                </Stack>
                                <Stack
                                  direction="column"
                                  justifyContent="center"
                                >
                                  <Typography className="user-name">
                                    {item?.customerName}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell className="body-cell">
                              {item?.customerKhName}
                            </TableCell>
                            <TableCell className="body-cell">
                              <Grid container columnSpacing={2} rowSpacing={1}>
                                {item?.contacts?.map((e, index) => {
                                  return (
                                    <Grid item sx={6} key={index}>
                                      {e?.contact === "" ? null : (
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          spacing={0.4}
                                        >
                                          {IconData(e.icon)}
                                          <Typography fontSize={"12px"}>
                                            {e?.contact}
                                          </Typography>
                                        </Stack>
                                      )}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </TableCell>
                            <TableCell className="body-cell">
                              {item?.address}
                            </TableCell>
                            <TableCell className="body-cell">
                              {item?.remark}
                            </TableCell>
                            <TableCell className="body-cell-end">
                              <CustomerAction
                                editData={item}
                                setRefetch={refetch}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
