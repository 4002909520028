import React, { useContext } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//Srcs
import "./setting.scss";
import User from "../Assets/report.png";

export default function Setting() {
  // let navigate = useNavigate()
  return (
    <div className="setting-page">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Setting</Typography>
        </Stack>
      </Stack>

      <Box sx={{ mt: "30px" }}>
        <Grid container spacing={4}>
          {/* =========================================== Page setup Service =========================================== */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Link to="/setting/service" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={3}
                className="setting-page-container"
              >
                <Stack direction="column" justifyContent="center">
                  <img alt="Exam icon" className="image" src={User} />
                </Stack>
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Typography className="page-title">Set Up Service</Typography>
                  <Typography className="page-description">
                    Create update and delete service
                  </Typography>
                </Stack>
              </Stack>
            </Link>
          </Grid>

          {/* =========================================== Page setup Tax =========================================== */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Link to="/setting/tax" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={3}
                className="setting-page-container"
              >
                <Stack direction="column" justifyContent="center">
                  <img alt="Exam icon" className="image" src={User} />
                </Stack>
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Typography className="page-title">Set Up Tax</Typography>
                  <Typography className="page-description">
                    Create update and delete tax
                  </Typography>
                </Stack>
              </Stack>
            </Link>
          </Grid>

          {/* =========================================== Page setup Offer =========================================== */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Link to="/setting/offer" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={3}
                className="setting-page-container"
              >
                <Stack direction="column" justifyContent="center">
                  <img alt="Exam icon" className="image" src={User} />
                </Stack>
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Typography className="page-title">Set up offer</Typography>
                  <Typography className="page-description">
                    Create update and delete offer
                  </Typography>
                </Stack>
              </Stack>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
