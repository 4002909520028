import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormHelperText,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import {
  SelectCustomer,
  SelectProject,
} from "../../Components/Include/DynamicSelect";
import ServiceList from "./ServiceList";
import FreeList from "./FreeList";
import { CREATE_INVOICE, GET_EXCHANGE_RATE } from "../../Schema/Invoice";
import { currencyFormat, rielsFormat } from "../../Function/DynamicFn";

export default function CreateInvoice({ open, handleClose, setRefetch }) {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [projectVal, setProjectVal] = useState({ id: "", title: "" });
  const [checkedVal, setCheckedVal] = useState("");
  const [totalVal, setTotalVal] = useState(0);
  const [vatVal, setVatVal] = useState(0);
  const [exchangeRate, setExchangeRate] = useState("");

  const { refetch } = useQuery(GET_EXCHANGE_RATE, {
    onCompleted: ({ getExchangRate }) => {
      setExchangeRate(getExchangRate);
    },
  });

  const [freeListData, setFreeListData] = useState([
    { key: Date.now() + Math.random(), value: { id: "", title: "" } },
  ]);

  const [requiredField, setRequiredField] = useState(false);

  //============================== SERVICE LIST DATA ======================================
  const mainItem = {
    service: { id: "", title: "", price: 0 },
    tax: { id: "", title: "", price: 0 },
    qty: 1,
    subTotal: 0,
    vat: 0,
  };
  const feature = [{ key: Date.now() + Math.random(), featureVal: "" }];
  const ItemServiceList = {
    key: Date.now() + Math.random(),
    mainItem,
    feature,
  };
  const [serviceListData, setServiceListData] = useState([ItemServiceList]);
  //========================= HANDLE ADD SERVICE LIST =====================================
  const hanldeAddServiceList = () =>
    setServiceListData([...serviceListData, ItemServiceList]);

  const [createInvoice] = useMutation(CREATE_INVOICE, {
    onCompleted: ({ createInvoice }) => {
      setLoading(false);
      if (createInvoice?.status === true) {
        setAlert(true, "success", createInvoice?.message);
        handleClose();
        resetForm();
        setRefetch();
        setCheckedVal("");
        setRequiredField(false);
        setProjectVal({ id: "", title: "" });
        setServiceListData([ItemServiceList]);
        setCustomerVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createInvoice?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    project: Yup.string().required("required"),
    paymentDate: Yup.date().required("required"),
    startDate: Yup.date(),
    endDate: Yup.date(),
    billTo: Yup.string().required("required"),
    note: Yup.string(),
    typeInvoice: Yup.string(),
    exchangeRate: Yup.number().required("required"),
    finalTotalKHR: Yup.number(),
    finalTotalUSD: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      project: "",
      paymentDate: moment(),
      startDate: moment(),
      endDate: moment(),
      billTo: "",
      note: "",
      typeInvoice: "One Time",
      exchangeRate: 0,
    },

    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (serviceListData.length !== 0) {
          serviceListData.map((e) => {
            if (Boolean(e?.mainItem?.service?.id)) createStatus = true;
            else {
              createStatus = false;
              setRequiredField(true);
              setLoading(false);
              throw BreakException;
            }
          });
        }
      } catch (e) {
        setLoading(false);
        if (e !== BreakException) throw e;
      }

      if (createStatus) {
        setLoading(true);
        const items = serviceListData.map((e) => {
          const itemsDes = e?.feature
            .map((i) => {
              if (Boolean(i.featureVal)) return i.featureVal;
            })
            .filter((e) => e !== undefined);
          return {
            iteme: Boolean(e?.mainItem?.service?.id)
              ? e?.mainItem?.service?.id
              : null,
            itemsDes: itemsDes,
            qty: parseFloat(e?.mainItem?.qty),
            tax: Boolean(e?.mainItem?.tax?.id) ? e?.mainItem?.tax?.id : null,
          };
        });

        const FreeValue = freeListData
          .filter((e) => {
            if (Boolean(e.value)) return e.value.id;
          })
          .map((i) => {
            return i.value.id;
          });
        // console.log("finalTotalUSD=====>", totalVal + vatVal);
        // console.log(" exchangeRate=====>", values);
        // console.log(
        //   "finalTotalKHR=====>",
        //   (totalVal + vatVal) * values?.exchangeRate
        // );
        createInvoice({
          variables: {
            input: {
              project: values?.project,
              paymentDate: moment(values?.paymentDate).format("YYYY-MM-DD"),
              startDate: moment(values?.startDate).format("YYYY-MM-DD"),
              endDate: moment(values?.endDate).format("YYYY-MM-DD"),
              billTo: values?.billTo,
              note: values?.note,
              typeInvoice: values?.typeInvoice,
              exchangeRate: values?.exchangeRate,
              items: items,
              free: FreeValue,
              finalTotalKHR: (totalVal + vatVal) * values?.exchangeRate,
              finalTotalUSD: totalVal + vatVal,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (exchangeRate) {
      setFieldValue("exchangeRate", exchangeRate);
    }
  }, [exchangeRate]);

  useEffect(() => {
    setFieldValue("project", "");
    if (Boolean(projectVal?.id)) setFieldValue("project", projectVal?.id);
  }, [projectVal?.id]);

  useEffect(() => {
    setFieldValue("billTo", "");
    if (Boolean(customerVal?.id)) setFieldValue("billTo", customerVal?.id);
  }, [customerVal?.id]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Create Invoice</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography className="field-title">Exchange rate</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type="number"
                  placeholder="exchange rate"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">៛</InputAdornment>
                    ),
                  }}
                  {...getFieldProps("exchangeRate")}
                  error={Boolean(touched.exchangeRate && errors.exchangeRate)}
                  helperText={touched.exchangeRate && errors.exchangeRate}
                />
              </Grid>

              <Grid item xs={8}>
                <Typography className="field-title">Select project</Typography>
                <SelectProject
                  selectedVal={projectVal}
                  setSelectedVal={setProjectVal}
                />
                {!!errors.project && touched.project && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.project}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Payment date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.paymentDate}
                    onChange={(e) => setFieldValue("paymentDate", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Bill to</Typography>
                <SelectCustomer
                  selectedVal={customerVal}
                  setSelectedVal={setCustomerVal}
                />
                {!!errors.billTo && touched.billTo && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.billTo}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Select type</Typography>
                <FormControl className="select-role" fullWidth size="small">
                  <Select
                    value={values?.typeInvoice}
                    onChange={(e) =>
                      setFieldValue("typeInvoice", e.target.value)
                    }
                  >
                    <MenuItem value="One Time">One Time</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {values?.typeInvoice === "One Time" ? null : (
                <>
                  <Grid item xs={4}>
                    <Typography className="field-title">Start date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd-MM-yyyy"
                        value={values?.startDate}
                        onChange={(e) => setFieldValue("startDate", e)}
                        className="search-field"
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="field-title">End date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd-MM-yyyy"
                        value={values?.endDate}
                        onChange={(e) => setFieldValue("endDate", e)}
                        className="search-field"
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="field-title" mt={1}>
                    Service
                  </Typography>
                  <IconButton onClick={hanldeAddServiceList}>
                    <AddBoxOutlinedIcon sx={{ color: "#0f81c2" }} />
                  </IconButton>
                </Stack>
                {/* ========================= SERVIVE LIST ================================ */}
                <ServiceList
                  requiredField={requiredField}
                  serviceListData={serviceListData}
                  setServiceListData={setServiceListData}
                  setTotalVal={setTotalVal}
                  setVatVal={setVatVal}
                />
              </Grid>
              <Grid item xs={12}>
                <FreeList
                  freeListData={freeListData}
                  setFreeListData={setFreeListData}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Note</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="note"
                  {...getFieldProps("note")}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction={"row-reverse"} spacing={12}>
                  <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {currencyFormat(totalVal)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {currencyFormat(vatVal)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {currencyFormat(totalVal + vatVal)}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography className="field-title">
                        {rielsFormat(
                          (totalVal + vatVal) * values?.exchangeRate
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"column"} spacing={1}>
                    <Stack direction={"row-reverse"}>
                      <Typography>Sub Total ( $ )</Typography>
                    </Stack>
                    <Stack direction={"row-reverse"}>
                      <Typography>VAT ( $ )</Typography>
                    </Stack>
                    <Stack direction={"row-reverse"}>
                      <Typography>Total Amount ( $ )</Typography>
                    </Stack>
                    <Stack direction={"row-reverse"}>
                      <Typography>Total Amount ( Riels )</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
