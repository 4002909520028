import React, { useContext } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//Icons
import { FaRegCircleUser } from "react-icons/fa6";
import {
  LiaFileInvoiceDollarSolid,
  LiaFileContractSolid,
} from "react-icons/lia";
import { GoPeople, GoProjectSymlink } from "react-icons/go";
import { AiOutlineSetting } from "react-icons/ai";
import { MdOutlineRequestQuote } from "react-icons/md";
//Srcs
import CompanyLogo from "../../Assets/Go_Global_IT_Logo.png";
import "./menunavbar.scss";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbar({ open }) {
  let location = useLocation();
  const navigate = useNavigate();

  // console.log("location::", location?.pathname)

  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  return (
    <Drawer variant="permanent" open={open} className="drawer-menu">
      <DrawerHeader sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
          <IconButton
            onClick={() => navigate("/project")}
            className="image-container"
          >
            <img src={CompanyLogo} alt="logo" className="logo" />
          </IconButton>
        </Stack>
      </DrawerHeader>

      <List className="list-menu">
        {/* =================================== Page Project ============================= */}
        <ListItem
          className={
            location.pathname === "/project" ||
            location.pathname === "/project/project-details"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/project")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <GoProjectSymlink className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Project</Typography>
          </ListItemButton>
        </ListItem>

        {/* ============================== Page Quatation ========================== */}
        <ListItem
          className={
            location.pathname === "/quotation"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/quotation")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <MdOutlineRequestQuote className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Quotation</Typography>
          </ListItemButton>
        </ListItem>

        {/* ============================== Page Contract ========================== */}
        <ListItem
          className={
            location.pathname === "/contract" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => navigate("/contract")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <LiaFileContractSolid className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Contract</Typography>
          </ListItemButton>
        </ListItem>

        {/* ============================== Page Invoice ========================== */}
        <ListItem
          className={
            location.pathname === "/invoice" ||
            location.pathname === "/invoice/invoice-detail"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/invoice")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <LiaFileInvoiceDollarSolid className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Invoice</Typography>
          </ListItemButton>
        </ListItem>

        {/* ================================= Page Customer ================================ */}
        <ListItem
          className={
            location.pathname === "/customer" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => navigate("/customer")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <GoPeople className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Customer</Typography>
          </ListItemButton>
        </ListItem>

        {/* ================================= Page User ================================ */}
        <ListItem
          className={
            location.pathname === "/user" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => navigate("/user")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <FaRegCircleUser className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">User</Typography>
          </ListItemButton>
        </ListItem>

        {/* ================================= Page Payment ================================ */}
        <ListItem
          className={
            location.pathname === "/payment" ? "list-item-active" : "list-item"
          }
          disablePadding
          onClick={() => navigate("/payment")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <FaRegCircleUser className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Payment</Typography>
          </ListItemButton>
        </ListItem>

        {/* ================================= Page Report ============================== */}
        {/* <ListItem
          className={
            location.pathname === "/report" ||
            location.pathname === "/report/student-and-parents" ||
            location.pathname === "/report/student-statistics" ||
            location.pathname === "/report/student-transfer-out" ||
            location.pathname === "/report/scholarship-report" ||
            location.pathname === "/report/student-drop-out"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/report")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <HiOutlineClipboardDocumentList className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Reports</Typography>
          </ListItemButton>
        </ListItem> */}
      </List>

      <Box sx={{ flexGrow: 1 }}></Box>

      <List className="list-menu">
        {/* =============================== Page Setting =============================== */}
        <ListItem
          className={
            location.pathname === "/setting" ||
            location.pathname === "/setting/service" ||
            location.pathname === "/setting/tax" ||
            location.pathname === "/setting/offer"
              ? "list-item-active"
              : "list-item"
          }
          disablePadding
          onClick={() => navigate("/setting")}
        >
          <ListItemButton className="list-item-button">
            <ListItemIcon className="list-item-icon">
              <AiOutlineSetting className="icon" />
            </ListItemIcon>
            <Typography className="list-item-text">Setting</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
