import React, { useContext } from "react";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Radio,
  IconButton,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { UPDATE_QUOTATION_STATUS } from "../../Schema/Quotation";

export default function UpdateQuotationStatus({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);

  const [updateQuotationStatus] = useMutation(UPDATE_QUOTATION_STATUS, {
    onCompleted: ({ updateQuotationStatus }) => {
      if (updateQuotationStatus?.status === true) {
        setAlert(true, "success", updateQuotationStatus?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateQuotationStatus?.message);
      }
    },
    onError: (error) => {
      console.log("Error", error?.message);
    },
  });

  const handleUpdateStatus = (status) => {
    updateQuotationStatus({
      variables: {
        id: editData?._id,
        status: status,
      },
    });
  };

  const dataStatus = [
    {
      label: "Pending",
      color: "#ff9b00",
    },
    {
      label: "FollowUp",
      color: "#7a44ff",
    },
    {
      label: "Approve",
      color: "#068665",
    },
    {
      label: "Cancel",
      color: "#f63747",
    },
  ];

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              UPDATE QUOTATION STATUS
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                defaultValue="female"
                onClick={(e) => console.log(e.target?.value)}
              >
                {dataStatus.map((item) => (
                  <FormControlLabel
                    value={item?.label}
                    checked={item?.label === editData?.status ? true : false}
                    control={<Radio style={{ color: item.color }} />}
                    onChange={(e) => handleUpdateStatus(item?.label)}
                    label={
                      <span
                        style={{
                          color: item.color,
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.label}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
