import { gql } from "@apollo/client";


export const GET_CUSTOMER_FOR_SELECT = gql`
query GetCustomerForSelect {
  getCustomerForSelect {
    _id
    customerName
  }
}
`;