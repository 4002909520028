import React, { useState, useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  Tab,
  Avatar,
  Checkbox,
} from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
//Icons
import { MdOutlineDateRange } from "react-icons/md";
//Src
import "../../Pages/project.scss";
import CreateIcon from "../../Assets/create-icon.svg";
import { AuthContext } from "../../Context/AuthContext";

import FooterPagination from "../Include/FooterPagination";
import CreateNote from "./CreateNote";
import {
  GET_NOTE_WITH_PAGINATION,
  CHECK_NOTE_STATUS,
} from "../../Schema/Project";
import NoteAction from "./NoteAction";

export default function ProjectDetails() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [projectId, setProjectId] = useState(params.get("projectId"));

  const { setAlert } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => setValue(newValue);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { refetch } = useQuery(GET_NOTE_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      projectId: projectId,
    },
    onCompleted: ({ getNotesPagination }) => {
      setTableData(getNotesPagination?.data);
      setPaginationData(getNotesPagination?.paginator);
      setLoading(false);
      // console.log("getNotesPagination::", getNotesPagination)
    },
    onError: (err) => {
      setLoading(false);
      setTableData([]);
      console.log("Error:::::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const [checkNoteStatus] = useMutation(CHECK_NOTE_STATUS, {
    onCompleted: ({ checkNoteStatus }) => {
      setLoading(false);
      if (checkNoteStatus?.status === true) {
        setAlert(true, "success", checkNoteStatus?.message);
        // setNoteListData([newNoteData]);
      } else {
        setAlert(true, "error", checkNoteStatus?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  const handdleCheckStatus = (id, noteId, status) => {
    checkNoteStatus({
      variables: {
        id: id,
        noteId: noteId,
        status: status,
      },
    });
  };

  return (
    <Box className="project-detail-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link to="/project" style={{ textDecoration: "none" }}>
            <Typography className="page-title">Project</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">PROJECT NAME</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <TabContext value={value}>
          <Stack direction="row" spacing={2}>
            <Box
              sx={{
                width: "88%",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList onChange={handleChange}>
                <Tab
                  label="Project Note"
                  value="1"
                  sx={{ fontWeight: "bold" }}
                />
              </TabList>
            </Box>

            <Stack direction="row" justifyContent="right" sx={{ width: "12%" }}>
              <Stack direction="column" justifyContent="center">
                <Button
                  onClick={handleOpen}
                  className="btn-create"
                  startIcon={
                    <img
                      src={CreateIcon}
                      alt="CreateIcon"
                      className="icon-add"
                    />
                  }
                >
                  Create
                </Button>
                <CreateNote
                  open={open}
                  handleClose={handleClose}
                  setRefetch={refetch}
                  projectId={projectId}
                />
              </Stack>
            </Stack>
          </Stack>

          <TabPanel value="1">
            <Box>
              {tableData?.map((data, index) => {
                // console.log("data::", data);
                return (
                  <Box className="card-container" key={index}>
                    <Stack padding="28px" direction="column" spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        {/* <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{ width: 70, height: 70 }}
                            alt={data?.customerName}
                            src={
                              "https://i.pinimg.com/236x/87/66/e5/8766e5f221fa30acb078d6d2d6b7af81.jpg"
                            }
                          />
                          <Stack direction="column" spacing={1}>
                            <Typography className="customer-label">
                              {data?.lastUpdateBy}
                            </Typography>
                            <Stack direction="row" spacing={0.6}>
                              <MdOutlineDateRange className="icon-date" />
                              <Typography className="date">
                                {moment(data?.lastUpdateAt).format(
                                  "DD MMM YYYY"
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack> */}
                        <Typography textAlign="justify">
                          {data?.description}
                        </Typography>
                        <NoteAction
                          editData={data}
                          setRefetch={refetch}
                          projectId={projectId}
                        />
                      </Stack>
                      {/* <Typography textAlign="justify">
                        {data?.description}
                      </Typography> */}
                      <Stack direction="column" spacing={1}>
                        {data?.notes?.map((row, index) => {
                          return (
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              key={index}
                            >
                              <Checkbox
                                className="project-des"
                                sx={{ padding: "0px" }}
                                checked={row?.complate}
                                onChange={(e) =>
                                  handdleCheckStatus(
                                    data?._id,
                                    row?.noteId,
                                    e?.target?.checked
                                  )
                                }
                              />
                              <Typography
                                className="project-des"
                                textAlign="justify"
                              >
                                {row?.title}
                              </Typography>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
        </TabContext>
        {/* ==================== Pagination  ============================= */}
        <Stack direction="row" justifyContent="right" sx={{ width: "100%" }}>
          <FooterPagination
            totalPages={paginationData?.totalPages}
            totalDocs={paginationData?.totalDocs}
            limit={limit}
            page={page}
            setPage={setPage}
            handleLimit={handleLimit}
          />
        </Stack>
      </Box>
    </Box>
  );
}
