import React, { useState } from "react";
import { Stack, IconButton, Box, Tooltip } from "@mui/material";
//Icons
import { MdOutlineGppGood } from "react-icons/md";
import { MdDelete, MdModeEdit } from "react-icons/md";
//Srcs
import DeleteContract from "./DeleteContract";
import UpdateContract from "./UpdateContract";

export default function ContrackAction({ editData, setRefetch }) {
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  return (
    <>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="Update" placement="top">
          <IconButton onClick={handleOpenUpdate}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <UpdateContract
        open={openUpdate}
        handleClose={handleCloseUpdate}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteContract
        open={openDelete}
        handleClose={handleCloseDelete}
        editData={editData}
        setRefetch={setRefetch}
      />
    </>
  );
}
