import { gql } from "@apollo/client";

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($input: invoiceInput!) {
    createInvoice(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($id: ID!, $input: invoiceInput!) {
    updateInvoice(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($id: ID!) {
    deleteInvoice(_id: $id) {
      status
      message
    }
  }
`;

export const GET_INVOICE_WITH_PAGINATION = gql`
  query GetInvoicesPagination(
    $page: Int!
    $limit: Int!
    $project: ID!
    $customer: ID!
    $payment: String!
    $type: String!
  ) {
    getInvoicesPagination(
      page: $page
      limit: $limit
      project: $project
      customer: $customer
      payment: $payment
      type: $type
    ) {
      data {
        _id
        invoiceNumber
        projectId
        projectName
        billToId
        billToCustomer
        paymentDate
        paymentMethod
        payment
        type
        items {
          itemeId
          itemeName
          itemePrice
          taxName
          taxId
          tax
          qty
          totalAmount
          itemsDes
        }
        finalTotalUSD
        finalTotalKHR
        exchangeRate
        free
        note
        void
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query GetInvoiceById($id: ID!) {
    getInvoiceById(_id: $id) {
      _id
      invoiceNumber
      projectId
      type
      projectName
      billToId
      billToCustomer
      paymentDate
      startDate
      endDate
      paymentMethod
      payment
      items {
        itemeId
        itemeName
        itemePrice
        taxName
        taxId
        tax
        qty
        unit
        totalAmount
        itemsDes
      }
      finalTotalUSD
      finalTotalKHR
      exchangeRate
      free
      note
      void
    }
  }
`;

export const UPDATE_INVOICE_STATUS = gql`
  mutation UpdatePaymentStatus($id: ID!, $status: String!) {
    updatePaymentStatus(_id: $id, status: $status) {
      status
      message
    }
  }
`;

export const VOID_INVOICE = gql`
  mutation VoidInvoice($id: ID!) {
    voidInvoice(_id: $id) {
      status
      message
    }
  }
`;

export const GET_EXCHANGE_RATE = gql`
  query Query {
    getExchangRate
  }
`;
