import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: InputCreateUser!) {
    createUser(input: $input) {
      status
      message
    }
  }
`;

export const GET_USER_WITH_PAGINATION = gql`
  query GetUsersPagination($page: Int!, $limit: Int!, $keyword: String!) {
    getUsersPagination(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        firstName
        lastName
        email
        userProfile
        role
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      _id
      firstName
      lastName
      email
      userProfile
      role
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateAcademicUserRole($id: ID!, $role: AllowUserRole!) {
    updateAcademicUserRole(_id: $id, role: $role) {
      status
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(_id: $id) {
      status
      message
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query GetEmployees($keyword: String!) {
    getEmployees(keyword: $keyword) {
      _id
      firstName
      lastName
      email
      password
      userProfile
      role
      createdAt
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query GetEmployeeByID($id: String!) {
    getEmployeeByID(_id: $id) {
      _id
      firstName
      lastName
      email
      password
      userProfile
      role
      createdAt
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($id: ID!) {
    forgotPassword(_id: $id) {
      status
      message
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin(
    $email: String!
    $password: String!
    $client: AllowUserPlatform
  ) {
    userLogin(email: $email, password: $password, client: $client) {
      token
      role
      message
      status
    }
  }
`;
