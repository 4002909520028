import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: porjectInput!) {
    createProject(input: $input) {
      status
      message
    }
  }
`;

export const GET_PROJECT_WITH_PAGINATION = gql`
  query GetProjectsPagination(
    $page: Int!
    $limit: Int!
    $customer: ID!
    $payment: String!
    $status: String!
    $keyword: String
  ) {
    getProjectsPagination(
      page: $page
      limit: $limit
      customer: $customer
      payment: $payment
      status: $status
      keyword: $keyword
    ) {
      data {
        _id
        numbering
        projectName
        description
        customerId
        customerName
        customerProfile
        startDate
        endDate
        paymentStatus
        price
        payment
        status
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: porjectInput!) {
    updateProject(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(_id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_STATUS = gql`
  mutation UpdateProjectStatus($id: ID!, $status: String!) {
    updateProjectStatus(_id: $id, status: $status) {
      status
      message
    }
  }
`;

export const GET_PROJECT_FOR_SELECT = gql`
  query GetProjectsForSelect {
    getProjectsForSelect {
      _id
      projectName
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: InputNote!) {
    createNote(input: $input) {
      status
      message
    }
  }
`;

export const GET_NOTE_WITH_PAGINATION = gql`
  query GetNotesPagination(
    $page: Int!
    $limit: Int!
    $keyword: String!
    $projectId: ID!
  ) {
    getNotesPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      projectId: $projectId
    ) {
      data {
        _id
        noteTitle
        description
        projectId
        projectName
        notes {
          noteId
          title
          complate
        }
        lastUpdateBy
        lastUpdateAt
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($id: ID!, $input: InputNote!) {
    updateNote(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const CHECK_NOTE_STATUS = gql`
  mutation CheckNoteStatus($id: ID!, $noteId: ID!, $status: Boolean) {
    checkNoteStatus(_id: $id, noteId: $noteId, status: $status) {
      status
      message
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(_id: $id) {
      status
      message
    }
  }
`;
