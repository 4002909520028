import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import imageCompression from "browser-image-compression";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyImage from "../../Assets/empty-image.png";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { CREATE_CUSTOMER } from "../../Schema/Customer";
import CropImageFile from "../CropImage/CropImageFile";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateCustomer({ open, handleClose, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  const handleUploadImage = async (e) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    var newFile = new File([compressedFile], `${imageFile.name}.png`, {
      type: "image/png",
    });
    // console.log("imageFile::", imageFile);
    // console.log("newFile::", newFile);

    if (newFile) {
      setImageFile(newFile);
      setPhotoURL(URL.createObjectURL(newFile));
      setOpenCrop(true);
    }
  };

  const typeData = [
    "Phone number",
    "Email",
    "Facebook",
    "YouTube",
    "Instagram",
    "TikTok",
    "Snapchat",
    "Twitter",
    "LinkedIn",
    "WhatsApp",
    "Messenger",
    "WeChat",
  ];
  // ======================= End upload Image =============================
  const newSocailMediaData = {
    key: Date.now() + Math.random(),
    icon: "Facebook",
    contact: "",
  };
  // ==================== Socai media List by sakada ======================

  const [socailMedia, setSocailMedia] = useState([newSocailMediaData]);
  // handle Add Socail Media
  const handleAddSocailMedia = () => {
    setSocailMedia([newSocailMediaData, ...socailMedia]);
  };

  // handle Delete Socail Media
  const handleDeleteSocailMedia = (key) => {
    let deleteSocailMedia = socailMedia?.filter((t) => t.key !== key);
    setSocailMedia(deleteSocailMedia);
  };

  // ================= handle Update Socail Media ==================
  const setUpdateContact = (key, value) => {
    const newSocailMedia = socailMedia?.map((e) => {
      if (e.key === key) e.contact = value;
      return e;
    });
    setSocailMedia([...newSocailMedia]);
  };

  //=================== handle change Socail Media =================
  const handleChangeIcon = (key, value) => {
    const newSocailMedia = socailMedia?.map((e) => {
      if (e.key === key) e.icon = value;
      return e;
    });
    setSocailMedia([...newSocailMedia]);
  };

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: ({ createCustomer }) => {
      setLoading(false);
      if (createCustomer?.status === true) {
        setAlert(true, "success", createCustomer?.message);
        handleClose();
        resetForm();
        setRefetch();
        setSocailMedia([newSocailMediaData]);
        setRequiredField(false);
      } else {
        setAlert(true, "error", createCustomer?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const validateSchema = Yup.object().shape({
    customerName: Yup.string().required("required"),
    customerKhName: Yup.string().required("required"),
    remark: Yup.string(),
    img: Yup.string(),
    address: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      customerName: "",
      customerKhName: "",
      contact: "",
      remark: "",
      icon: "",
      img: "",
      address: "",
    },

    validationSchema: validateSchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      let createStatus = false;
      const BreakException = {};
      try {
        if (socailMedia.length === 0) createStatus = true;
        if (socailMedia.length !== 0) {
          socailMedia.map((e) => {
            if (Boolean(e.contact)) createStatus = true;
            else {
              createStatus = false;
              setRequiredField(true);
              setLoading(false);
              throw BreakException;
            }
          });
        }
      } catch (e) {
        setLoading(false);
        if (e !== BreakException) throw e;
      }

      if (createStatus) {
        const contacts = socailMedia
          .filter((e) => e.contact !== "")
          ?.map((e) => {
            return {
              contact: e.contact,
              icon: e.icon,
            };
          });
        setLoading(true);

        createCustomer({
          variables: {
            ...values,
            contacts: contacts,
            img: profileHook,
          },
        });
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog open={open} className="dialog-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Create Customer</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            {!openCrop ? (
              <Box>
                <Stack direction="row" justifyContent="center">
                  <Tooltip title="click to upload">
                    <Button component="label">
                      <TextField
                        type="file"
                        id="image"
                        sx={{ display: "none" }}
                        onChange={handleUploadImage}
                      />
                      <Avatar
                        className="avater-image"
                        src={
                          imageFile
                            ? URL.createObjectURL(imageFile)
                            : EmptyImage
                        }
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Box>
            ) : (
              <CropImageFile
                setProgress={setProgress}
                setStatusProgress={setStatusProgress}
                openCrop={openCrop}
                setOpenCrop={setOpenCrop}
                photoURL={photoURL}
                setPhotoURL={setPhotoURL}
                setImageFile={setImageFile}
                setProfileHook={setProfileHook}
              />
            )}
            {statusProgress && progress < 100 ? (
              <Box sx={{ width: "100%", marginLeft: "20px" }}>
                <LinearProgressWithLabel value={progress} />
              </Box>
            ) : (
              <Typography
                className="field-title"
                sx={{ textAlign: "center", marginTop: "5px" }}
              >
                Profile image
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography className="field-title">Latin name</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="latin name"
              {...getFieldProps("customerName")}
              error={Boolean(touched.customerName && errors.customerName)}
              helperText={touched.customerName && errors.customerName}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className="field-title">Khmer name</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="khmer name"
              {...getFieldProps("customerKhName")}
              error={Boolean(touched.customerKhName && errors.customerKhName)}
              helperText={touched.customerKhName && errors.customerKhName}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between">
                <Stack sx={{ width: "35%" }}>
                  <Typography className="field-title" sx={{ marginTop: "8px" }}>
                    Icon
                  </Typography>
                </Stack>

                <Stack sx={{ width: "65%", paddingLeft: "12px" }}>
                  <Typography className="field-title" sx={{ marginTop: "8px" }}>
                    Contact
                  </Typography>
                </Stack>
                <Button
                  className="btn-add"
                  endIcon={<AddCircleOutlineIcon className="add-icon" />}
                  onClick={handleAddSocailMedia}
                >
                  Add
                </Button>
              </Stack>

              <Stack
                direction="column"
                spacing={1.5}
                sx={{ marginTop: "15px" }}
              >
                {socailMedia?.map((item) => {
                  return (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      key={item?.key}
                      spacing={1}
                    >
                      <FormControl fullWidth size="small" sx={{ width: "35%" }}>
                        <Select
                          value={item.icon}
                          onChange={(e) => {
                            handleChangeIcon(item?.key, e.target.value);
                          }}
                        >
                          {typeData.map((e) => {
                            return (
                              <MenuItem value={e} key={e?.key}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <TextField
                        sx={{ width: "65%" }}
                        size="small"
                        multiline
                        placeholder="Contact Name"
                        value={item?.contact}
                        onChange={(e) => {
                          setUpdateContact(item?.key, e.target.value);
                        }}
                        error={!Boolean(item?.contact) && requiredField}
                        helperText={
                          !Boolean(item?.contact) && requiredField
                            ? "required !"
                            : null
                        }
                      />
                      <Stack>
                        <IconButton
                          onClick={() => handleDeleteSocailMedia(item?.key)}
                        >
                          <DeleteIcon sx={{ color: "#f54949" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography className="field-title">Address</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="address"
              {...getFieldProps("address")}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="field-title">Remark</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="Remark"
              {...getFieldProps("remark")}
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-create" fullWidth>
                {t("loading")}
              </Button>
            ) : (
              <Button
                className="btn-create"
                fullWidth
                onClick={handleSubmit}
                disabled={statusProgress && progress < 100 ? true : false}
              >
                {t("create")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
