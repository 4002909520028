import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import CreateIcon from "../Assets/create-icon.svg";
//Srcs
import "../Style/pagestyle.scss";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import CreateTax from "../Components/Tax/CreateTax";
import { GET_TAX } from "../Schema/Tax";
import TaxAction from "../Components/Tax/TaxAction";

export default function Tax() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_TAX, {
    onCompleted: ({ getTaxs }) => {
      // console.log("onCompleted", getTaxs);
      setTableData(getTaxs);
      setLoading(false);
    },
    onError: (error) => {
      console.log("error", error?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };

  useEffect(() => {
    refetch();
    document.title = "Tax";
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link to="/setting" style={{ textDecoration: "none" }}>
            <Typography className="page-title">Setting</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Tax</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item xs={6} sm={6} md={12} lg={3} xl={4.8}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreateTax
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell className="header-title-start" width="4%">N°</TableCell>
                <TableCell className="header-title" width="15%">Name</TableCell>
                <TableCell className="header-title"width="6%">Tax</TableCell>
                <TableCell className="header-title-end" width="75%"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell className="body-cell-start">
                              {index + 1}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.taxName}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.tax + " "} %
                            </TableCell>

                            <TableCell className="body-cell-end" align="right">
                              <TaxAction editData={row} setRefetch={refetch} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
