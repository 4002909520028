import React, { useState } from "react";
import {
  Typography,
  Stack,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
  Box,
} from "@mui/material";
//Icons
import { MdModeEdit, MdDelete } from "react-icons/md";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
//Srcs
import "../../Style/actionstyle.scss";
import DeleteNote from "./DeleteNote";
import UpdateNote from "./UpdateNote";

export default function NoteAction({ editData, setRefetch, projectId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  return (
    <div>
      <IconButton
        sx={{ width: "36px", height: "36px" }}
        onClick={handleOpenMenu}
      >
        <MoreHorizIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.30))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOpenEdit();
          }}
          sx={{
            padding: "0px 13px",
          }}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Tooltip title="Update" placement="top">
              <IconButton onClick={handleOpenEdit}>
                <Box className="update-container">
                  <MdModeEdit className="update-icon" />
                </Box>
              </IconButton>
            </Tooltip>
            <Typography className="update-title">Update</Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOpenDel();
          }}
          sx={{
            padding: "0px 13px",
          }}
        >
          <Stack direction="row" spacing={0.5} alignItems={"center"}>
            <Tooltip title="Delete" placement="top">
              <IconButton onClick={handleOpenDel}>
                <Box className="delete-container">
                  <MdDelete className="delete-icon" />
                </Box>
              </IconButton>
            </Tooltip>
            <Typography className="update-title">Delete</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <UpdateNote
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
        projectId={projectId}
      />
      <DeleteNote
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
