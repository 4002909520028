import { gql } from "@apollo/client";

export const CREATE_OFFER = gql`
  mutation CreateOffer($description: String!) {
    createOffer(description: $description) {
      status
      message
    }
  }
`;

export const GET_ALL_OFFER = gql`
  query GetOffers {
    getOffers {
      _id
      description
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: ID!, $description: String!) {
    updateOffer(_id: $id, description: $description) {
      status
      message
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: ID!) {
    deleteOffer(_id: $id) {
      status
      message
    }
  }
`;
