import React, { useState } from "react";
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";
//Icons
import DeleteIcon from "@mui/icons-material/Delete";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
//Srcs
import "../../Style/dialogstyle.scss";
import {
  SelectService,
  SelectTax,
} from "../../Components/Include/DynamicSelect";

export default function ServiceList({
  serviceListData,
  setServiceListData,
  requiredField,
  setTotalVal,
  setVatVal,
}) {
  const [serviceVal, setServiceVal] = useState({ id: "", title: "", price: 0 });
  const [taxVal, setTaxVal] = useState({ id: "", title: "" });

  // =========================== HANDLE DELETE MAIN ITEM SERVICE =================================

  const handleDeleteMainItemService = (key) => {
    const newServiceListData = serviceListData.filter((t) => t.key !== key);
    const total = newServiceListData
      .map((e) => {
        return Boolean(e.mainItem?.service?.id) ? e.mainItem?.subTotal : 0;
      })
      .reduce((a, b) => a + b, 0);

    const vat = newServiceListData
      .map((e) => {
        return Boolean(e.mainItem?.tax?.id) ? e.mainItem?.vat : 0;
      })
      .reduce((a, b) => a + b, 0);
    setVatVal(vat);
    setTotalVal(total);
    setServiceListData(newServiceListData);
  };

  // ========================== HANDLE ADD FEATURE ==============================================

  const handleAddFeature = (key) => {
    const newServiceListData = serviceListData.map((item) => {
      const feature = { key: Date.now() + Math.random(), featureVal: "" };
      if (key === item.key) item.feature = [...item.feature, feature];
      return item;
    });
    setServiceListData(newServiceListData);
  };

  // ========================== HANDLE REMOVE FEATURE ============================================

  const handleDeleteFeature = (mainKey, featurKey) => {
    const newServiceListData = serviceListData.map((item) => {
      if (item.key === mainKey)
        item.feature = item.feature.filter((t) => t.key !== featurKey);
      return item;
    });
    setServiceListData(newServiceListData);
  };

  // =========================== HANDLE UPDATE MAIN ITEM VALUE ===================================

  const handleUpdateValueMainItem = (key, value, fieldName) => {
    const newServiceListData = serviceListData.map((item) => {
      if (item.key === key && fieldName === "QTY") item.mainItem.qty = value;
      if (item.key === key && fieldName === "TAX") item.mainItem.tax = value;
      if (item.key === key && fieldName === "SERVICE")
        item.mainItem.service = value;

      item.mainItem.subTotal =
        parseFloat(item.mainItem.qty) *
        parseFloat(item.mainItem?.service?.price);

      item.mainItem.vat =
        (parseFloat(item.mainItem.subTotal) *
          parseFloat(item.mainItem?.tax?.price)) /
        100;

      return item;
    });

    const total = newServiceListData
      .map((e) => {
        return Boolean(e.mainItem?.service?.id) ? e.mainItem?.subTotal : 0;
      })
      .reduce((a, b) => a + b, 0);

    const vat = newServiceListData
      .map((e) => {
        return Boolean(e.mainItem?.tax?.id) && Boolean(e.mainItem?.service?.id)
          ? e.mainItem?.vat
          : 0;
      })
      .reduce((a, b) => a + b, 0);

    setVatVal(vat);
    setTotalVal(total);
    setServiceListData(newServiceListData);
  };

  // ================================ HANDLE UPDATE VALUES FEATURE ==============================

  const handleUpdateValueFeature = (mainKey, featureKey, value) => {
    const newServiceListData = serviceListData.map((item) => {
      if (item?.key === mainKey) {
        item.feature?.map((e) => {
          if (e.key === featureKey) e.featureVal = value;
          return e;
        });
      }
      return item;
    });
    setServiceListData(newServiceListData);
  };

  return (
    <Stack direction={"column"}>
      <Stack direction={"column"} spacing={2}>
        {serviceListData.map((item) => {
          // console.log("item===>", item);
          return (
            <Stack direction={"column"} key={item?.key} spacing={1}>
              <Divider sx={{ bgcolor: "#0f81c2 !important" }} />
              <Stack direction={"row"}>
                <Stack sx={{ width: "48%" }}>
                  <Typography fontWeight={"bold"}>Main Item</Typography>
                </Stack>

                <Stack sx={{ width: "7%" }}>
                  <Typography fontWeight={"bold"}>QTY</Typography>
                </Stack>

                <Stack sx={{ width: "15%" }}>
                  <Typography fontWeight={"bold"}>Amount</Typography>
                </Stack>

                <Stack sx={{ width: "25%" }}>
                  <Typography fontWeight={"bold"}>TAX</Typography>
                </Stack>
              </Stack>

              <Stack direction={"row"} spacing={1}>
                <Stack sx={{ width: "48%" }}>
                  <SelectService
                    requiredField={requiredField}
                    selectedVal={item.mainItem.service}
                    setSelectedVal={setServiceVal}
                    mainKey={item?.key}
                    handleUpdateValueMainItem={handleUpdateValueMainItem}
                  />
                </Stack>

                <Stack sx={{ width: "7%" }}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    defaultValue={1}
                    inputProps={{ min: 1 }}
                    value={item?.mainItem?.qty}
                    onChange={(e) =>
                      handleUpdateValueMainItem(
                        item?.key,
                        e.target.value,
                        "QTY"
                      )
                    }
                  />
                </Stack>

                <Stack sx={{ width: "15%" }}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    defaultValue={0}
                    inputProps={{ min: 1 }}
                    value={item?.mainItem?.subTotal}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      handleUpdateValueMainItem(
                        item?.key,
                        e?.target?.value,
                        "AMT"
                      )
                    }
                  />
                </Stack>

                <Stack sx={{ width: "25%" }}>
                  <SelectTax
                    selectedVal={item.mainItem.tax}
                    setSelectedVal={setTaxVal}
                    mainKey={item?.key}
                    handleUpdateValueMainItem={handleUpdateValueMainItem}
                  />
                </Stack>

                <Stack
                  sx={{ width: "5%" }}
                  direction="row"
                  justifyContent="center"
                >
                  <IconButton
                    onClick={() => handleDeleteMainItemService(item?.key)}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack direction="column" sx={{ width: "95%" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography className="field-title" mt={1} fontStyle="italic">
                    Feature
                  </Typography>
                  <IconButton onClick={() => handleAddFeature(item?.key)}>
                    <IoMdAdd color="#0f81c2" />
                  </IconButton>
                </Stack>
                <Divider sx={{ bgcolor: "#0f81c2 !important" }} />

                <Stack
                  direction={"column"}
                  spacing={1}
                  sx={{ marginTop: "10px" }}
                >
                  {item?.feature?.map((t) => {
                    return (
                      <Stack
                        direction="row"
                        key={item?.key}
                        spacing={1}
                      >
                        <TextField
                          className="text-field"
                          fullWidth
                          size="small"
                          placeholder="feature"
                          value={t?.featureVal}
                          onChange={(e) =>
                            handleUpdateValueFeature(
                              item?.key,
                              t?.key,
                              e?.target?.value
                            )
                          }
                        />
                        <IconButton
                          onClick={() => handleDeleteFeature(item?.key, t.key)}
                        >
                          <RiSubtractFill color="red" />
                        </IconButton>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
