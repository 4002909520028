import { gql } from "@apollo/client";

export const GET_CUSTOMER_PAGINATION = gql`
  query GetCustomerPagination($page: Int!, $limit: Int!, $keyword: String!) {
    getCustomerPagination(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        img
        customerName
        customerKhName
        address
        contacts {
          icon
          contact
        }
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $contacts: [ContactInput!]!
    $address: String!
    $img: String
    $customerName: String
    $customerKhName: String
    $remark: String
  ) {
    createCustomer(
      contacts: $contacts
      address: $address
      img: $img
      customerName: $customerName
      customerKhName: $customerKhName
      remark: $remark
    ) {
      status
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(_id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $id: ID!
    $contacts: [ContactInput!]!
    $address: String!
    $img: String
    $customerName: String
    $customerKhName: String
    $remark: String
  ) {
    updateCustomer(
      _id: $id
      contacts: $contacts
      address: $address
      img: $img
      customerName: $customerName
      customerKhName: $customerKhName
      remark: $remark
    ) {
      status
      message
    }
  }
`;
