import React, { useState, useContext } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
// ayouts
import Layout from "./Layout/Layout";
//Pages
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import User from "./Pages/User";
import Project from "./Pages/Project";
import Customer from "./Pages/Customer";
import Invoice from "./Pages/Invoice";
import Quotation from "./Pages/Quotation";
import Page404 from "./Pages/Page404";
import Setting from "./Pages/Setting";
import Service from "./Pages/Service";
import ProjectDetails from "./Components/Project/ProjectDetails";
import Tax from "./Pages/Tax";
import Offer from "./Pages/Offer";
import Contract from "./Pages/Contract";
import Payment from "./Pages/Payment";
import PreviewInvoiceDetail from "./Components/Invoice/PreviewInvoiceDetail";
// import ForgotPassword from "./Pages/ForgotPassword";
//Graphql
import { GET_USER_LOGIN } from "./Schema/User";
//Srcs
import { AuthContext } from "./Context/AuthContext";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {
  const { state } = useContext(AuthContext);

  // ========================= get user login ==========================
  const { data } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      // console.log("getUserLogin::", getUserLogin);
      if (getUserLogin?.email) {
        window.localStorage.setItem("userLogin", JSON.stringify(getUserLogin));
      }
    },
    fetchPolicy: "network-only",
  });

  // ====================== check state user login ==========================
  const [token, setToken] = useState(
    JSON.parse(window.localStorage.getItem("token"))
  );

  React.useEffect(() => {
    async function checkUser() {
      let userToke = await JSON.parse(window.localStorage.getItem("token"));
      if (userToke) {
        setToken(userToke);
        return;
      }

      if (state?.user?.token !== null && state?.user?.token !== undefined) {
        setToken(state?.user?.token);
        window.localStorage.setItem(
          "token",
          JSON.stringify(state?.user?.token)
        );
      } else {
        setToken(null);
      }
    }
    checkUser();
  }, [state?.user]);

  // ================================ check Route ==================================

  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "*", element: <Login /> },
  ]);

  const Content = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/project"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/project" /> },
        // { path: "dashboard", element: <Dashboard /> },
        { path: "project", element: <Project /> },
        { path: "customer", element: <Customer /> },
        { path: "invoice", element: <Invoice /> },
        { path: "quotation", element: <Quotation /> },
        { path: "contract", element: <Contract /> },
        { path: "/invoice/invoice-detail", element: <PreviewInvoiceDetail /> },
        { path: "/project/project-details", element: <ProjectDetails /> },
        { path: "user", element: <User /> },
        { path: "payment", element: <Payment /> },
        { path: "setting", element: <Setting /> },
        { path: "/setting/service", element: <Service /> },
        { path: "/setting/tax", element: <Tax /> },
        { path: "/setting/offer", element: <Offer /> },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (token !== null && token !== undefined) {
    return Content;
  } else {
    return LoginPage;
  }
}
