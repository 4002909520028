import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  Divider,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import ReactToPrint from "react-to-print";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import { GrFormSubtract } from "react-icons/gr";
//Srcs
import "./previewinvoicedetail.scss";
import LogoReport from "../../Assets/Itlogo-report.png";
import WingdingImage from "../../Assets/tacteing.jpg";
import { currencyFormat, rielsFormat } from "../../Function/DynamicFn";
import { GET_INVOICE_BY_ID } from "../../Schema/Invoice";

export default function PreviewInvoiceDetail({ open, handleClose, invoiceId }) {
  const [tableData, setTableData] = useState([]);
  const [itemData, setItemData] = useState([]);

  useQuery(GET_INVOICE_BY_ID, {
    variables: {
      id: invoiceId,
    },
    onCompleted: ({ getInvoiceById }) => {
      // console.log("getInvoiceById::", getInvoiceById);
      if (Boolean(getInvoiceById)) {
        setTableData(getInvoiceById);
        const newItem = getInvoiceById?.items?.map((item) => {
          const newItemsDes = item?.itemsDes.map((e) => {
            return e;
          });
          return {
            itemeName: item?.itemeName,
            startDate: getInvoiceById?.startDate,
            endDate: getInvoiceById?.endDate,
            qty: item?.qty,
            unit: item?.unit,
            totalAmount: item?.totalAmount,
            tax: item?.tax,
            itemsDes: newItemsDes,
          };
        });
        setItemData(newItem);
      }
    },
    onError: (e) => {
      console.log(e.message);
    },
  });

  // console.log("items::", itemData)

  const componentRef = useRef(null);
  const reactToPrintContent = () => {
    return componentRef.current;
  };

  const reactToPrintTrigger = () => {
    return (
      <Stack direction="row" justifyContent="right">
        <Button
          sx={{
            width: "30%",
            height: "38px",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#0f81c2 !important",
            color: "#fff",
          }}
        >
          Print
        </Button>
      </Stack>
    );
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">PRIVIEW INVOICE</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box ref={componentRef} className="print-invoice-container">
              <Box>
                <Grid item container>
                  <Grid item xs={3}>
                    <Stack direction="row" justifyContent="center">
                      <img src={LogoReport} className="invoice-logo-image" />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column">
                      <Typography className="invoice-title-Report">
                        ព្រះរាជាណាចក្រកម្ពុជា
                      </Typography>
                      <Typography className="invoice-title-En">
                        Kingdom Of Cambodia
                      </Typography>
                      <Typography className="invoice-title-Report">
                        ជាតិ​ សាសនា ព្រះមហាក្សត្រ
                      </Typography>
                      <Typography className="invoice-title-En">
                        Nation Religion King
                      </Typography>
                      <div className="invoice-tackteng-font">
                        <img
                          alt="Image"
                          src={WingdingImage}
                          className="invoice-image-tackteng"
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>

                <Box sx={{ marginTop: "20px" }}>
                  <Grid container>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Grid item xs={4.5}>
                      <Stack direction="column">
                        <Stack direction="row" spacing={0.3}>
                          <Typography className="invoice-text-head-title-bold">
                            ឈ្មោះសហគ្រាស :
                          </Typography>
                          <Typography className="invoice-text-head-title-bold">
                            ហ្គោគ្លូប៊លអាយធី
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Stack direction="column">
                            <Box className="invoice-text-head-title-bold">
                              អាស័យដ្ឋាន៖
                            </Box>
                          </Stack>

                          <Stack direction="column">
                            <Typography className="invoice-text-head-title">
                              ភូមិថ្មី សង្កាត់ស្វាយដង្គំ
                            </Typography>
                            <Typography className="invoice-text-head-title">
                              ក្រុងសៀមរាប ខេត្តសៀមរាប
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title">
                            Tel:
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            076 7772 168 / 085 70 55 55
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title">
                            VATTIN:
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            E116-2300000286
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        className="invoice-title-kh"
                        sx={{ marginTop: "-4px" }}
                      >
                        វិក្កយបត្រ
                      </Typography>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }} />
                    <Grid item xs={4.5}>
                      <Stack direction="column">
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title-en-bold">
                            N° :
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            {tableData?.invoiceNumber}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title-en-bold">
                            Bill To :
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            {tableData?.billToCustomer}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title-en-bold">
                            Payment Date :
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            {moment(tableData?.paymentDate).format(
                              "DD-MMM-YY hh:mm a"
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Box sx={{ marginTop: "14px" }}>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack direction={"column"} className="header-invoice">
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack width={"4%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            ល.រ
                          </Typography>
                        </Stack>
                        <Stack width={"40%"}>
                          <Typography className="header-text-invioce">
                            បរិយាយ
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            បរិមាណ
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography className="header-text-invioce">
                            ចាប់ពី
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography className="header-text-invioce">
                            រហូតដល់
                          </Typography>
                        </Stack>
                        <Stack width={"20%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "right" }}
                          >
                            សរុប
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack width={"4%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            N°
                          </Typography>
                        </Stack>
                        <Stack width={"40%"}>
                          <Typography className="header-text-invioce">
                            Description
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            Quantity
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography className="header-text-invioce">
                            Start date
                          </Typography>
                        </Stack>
                        <Stack width={"12%"}>
                          <Typography className="header-text-invioce">
                            End date
                          </Typography>
                        </Stack>
                        <Stack width={"20%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "right" }}
                          >
                            Total Amount
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "2px 0px" }} />
                    <Stack
                      direction={"column"}
                      spacing={0.6}
                      className="header-invoice"
                    >
                      {itemData?.map((e, index) => {
                        // console.log("e::", e);
                        return (
                          <Stack direction={"column"} spacing={0.5} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                            >
                              <Stack width={"4%"}>
                                <Typography
                                  className="invoice-des"
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  0{index + 1}
                                </Typography>
                              </Stack>
                              <Stack width={"40%"}>
                                <Typography
                                  className="invoice-des"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {e?.itemeName}
                                </Typography>
                              </Stack>
                              <Stack width={"12%"}>
                                <Typography
                                  className="invoice-des"
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {e?.qty} {e?.unit}
                                </Typography>
                              </Stack>
                              <Stack width={"12%"}>
                                {index === 0 ? (
                                  <Typography className="invoice-des">
                                    {e?.startDate
                                      ? moment(e?.startDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : null}
                                  </Typography>
                                ) : null}
                              </Stack>
                              <Stack width={"12%"}>
                                {index === 0 ? (
                                  <Typography className="invoice-des">
                                    {e?.endDate
                                      ? moment(e?.endDate).format("DD-MM-YYYY")
                                      : null}
                                  </Typography>
                                ) : null}
                              </Stack>
                              <Stack
                                width={"20%"}
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography
                                  className="invoice-des"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  $
                                </Typography>
                                <Typography
                                  className="invoice-des"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {currencyFormat(
                                    e?.totalAmount +
                                      (e?.tax * e?.totalAmount) / 100
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack direction={"column"} spacing={0.5}>
                              {e?.itemsDes?.map((i, key) => (
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  key={key}
                                >
                                  <Stack width={"4%"}></Stack>
                                  <Stack width={"40%"} spacing={1}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      spacing={0.5}
                                    >
                                      <GrFormSubtract fontSize="10px" />
                                      <Typography className="invoice-des-in-des">
                                        {i}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Stack width={"56%"}></Stack>
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        );
                      })}
                      {tableData?.free?.length !== 0 ? (
                        <>
                          <Stack direction={"column"} spacing={0.5}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              className="invoice-des-en"
                            >
                              <Stack width={"4%"}></Stack>
                              <Stack width={"96%"}>
                                <Typography
                                  className="invoice-des"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Free
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                          {tableData?.free?.map((i, key) => {
                            return (
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                key={key}
                              >
                                <Stack width={"4%"}></Stack>
                                <Stack width={"96%"} spacing={1}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={0.5}
                                  >
                                    <GrFormSubtract fontSize="10px" />
                                    <Typography className="invoice-des-in-des">
                                      {i}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      ) : null}
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack
                      direction={"column"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack width={"80%"}>
                          <Stack direction={"column"}>
                            <Typography className="invoice-text-head-title-total">
                              សរុប($) (បូកបញ្ចូលទាំងអាករ)
                            </Typography>
                            <Typography className="invoice-des-in-des">
                              Total ($) (VAT included)
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          width={"20%"}
                        >
                          <Stack direction="row">
                            <Typography className="invoice-total">$</Typography>
                            <Box flexGrow={1} />
                            <Typography
                              className="invoice-total"
                              sx={{ fontWeight: "bold" }}
                            >
                              {currencyFormat(tableData?.finalTotalUSD)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack
                      direction={"column"}
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        pacing={3}
                      >
                        <Stack width={"80%"} spacing={3}>
                          <Stack direction={"column"}>
                            <Typography className="invoice-text-head-title-total">
                              សរុប(រៀល)
                            </Typography>
                            <Typography className="invoice-des-in-des">
                              Total Amount (Riels)
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          width={"20%"}
                        >
                          <Stack direction="row">
                            <Box flexGrow={1} />
                            <Typography
                              className="invoice-total"
                              sx={{ fontWeight: "bold" }}
                            >
                              {rielsFormat(tableData?.finalTotalKHR)} ៛
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack direction="column" justifyContent="space-between">
                      <Stack direction="row">
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Typography className="invoice-text-head-title-total">
                            PAYMENT METHOD :
                          </Typography>
                          <Stack direction={"row"} spacing={0.4}>
                            <Stack direction={"column"} justifyContent="center">
                              <Typography className="invoice-text-head-title">
                                Cash
                              </Typography>
                            </Stack>
                            <Box className="invoice-check" />
                          </Stack>
                          <Stack direction={"row"} spacing={0.4}>
                            <Stack direction={"column"} justifyContent="center">
                              <Typography className="invoice-text-head-title">
                                ABA GO GLOBAL IT: USD 005 568 368
                              </Typography>
                            </Stack>
                            <Box className="invoice-check" />
                          </Stack>
                          <Stack direction={"row"} spacing={0.4}>
                            <Stack direction={"column"} justifyContent="center">
                              <Typography className="invoice-text-head-title">
                                KHR 005 833 665
                              </Typography>
                            </Stack>
                            <Box className="invoice-check" />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box sx={{ marginTop: "80px" }}>
                    <Grid Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-evenly"}
                          className="dividers-footer"
                        >
                          <Stack direction={"column"} spacing={1}>
                            <Divider className="invoice-footer-divider" />
                            <Typography className="invoice-text-head-title">
                              អ្នកបង់ប្រាប់(Payer)
                            </Typography>
                          </Stack>
                          <Stack direction={"column"}></Stack>
                          <Stack direction={"column"}></Stack>
                          <Stack direction={"column"} spacing={1}>
                            <Divider className="invoice-footer-divider" />
                            <Typography className="invoice-text-head-title">
                              បេឡាធិការ(Cashier)
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={`${tableData?.billToCustomer}-Invoice-${moment(
                tableData?.paymentDate
              ).format("DD-MMM-YYYY")}`}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
