import React, { useState } from "react";
import { Stack, IconButton, Tooltip, Box } from "@mui/material";
//Icons
import { MdDeleteOutline, MdModeEdit, MdDelete } from "react-icons/md";
import { RiFilePaper2Fill } from "react-icons/ri";
import { MdOutlineGppGood } from "react-icons/md";
//Srcs
import VoidInvoice from "./VoidInvoice";
import UpdateInvoice from "./UpdateInvoice";
import UpdateInvoiceStatus from "./UpdateInvoiceStatus";

export default function InvoiceAction({ editData, setRefetch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => setOpenStatus(false);

  return (
    <>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="Void" placement="top">
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <RiFilePaper2Fill className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Status" placement="top">
          <IconButton onClick={handleOpenStatus}>
            <Box className="status-container">
              <MdOutlineGppGood className="status-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      {/* <UpdateInvoice
        open={openUpdate}
        handleClose={handleCloseUpdate}
        editData={editData}
        setRefetch={setRefetch}
      /> */}

      <VoidInvoice
        open={openDelete}
        handleClose={handleCloseDelete}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UpdateInvoiceStatus
        open={openStatus}
        handleClose={handleCloseStatus}
        editData={editData}
        setRefetch={setRefetch}
      />
    </>
  );
}
