import React, { useState } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdDelete, MdModeEdit } from "react-icons/md";
import { BiReset } from "react-icons/bi";
//Srcs
import "../../Style/actionstyle.scss";
import DeleteUser from "./DeleteUser";
import ResetPassword from "./ResetPassword";

export default function UserAction({ editData, setRefetch }) {
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const userLogin = JSON.parse(window.localStorage.getItem("userLogin"));

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        {userLogin?.role === "superAdmin" ? (
          <Tooltip title="Reset password" placement="top">
            <IconButton onClick={handleOpenEdit}>
              <Box className="reset-container">
                <BiReset className="reset-icon" />
              </Box>
            </IconButton>
          </Tooltip>
        ) : null}

        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ResetPassword
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
      />
      <DeleteUser
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
