import React, { useContext, useState } from "react";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { DELETE_CUSTOMER } from "../../Schema/Customer";

export default function DeleteCustomer({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: ({ deleteCustomer }) => {
      setLoading(false);
      if (deleteCustomer?.status === true) {
        setAlert(true, "success", deleteCustomer?.message);
        handleClose();
        setRefetch();

        // let splitSrc = editData?.imageSrc.split(":")[4];

        // mutationDelete.mutate({
        //   storage: "Sek_Meas_HR",
        //   folder: "hr_sek_meas_images",
        //   file: splitSrc.split("/")[0],
        // });
      } else {
        setAlert(true, "error", deleteCustomer?.message);
      }
    },
    onError: (error) => {
      console.log("Error", error?.message);
    },
  });
  const handleDelete = () => {
    setLoading(true);
    deleteCustomer({
      variables: {
        id: editData?._id,
      },
    });
  };
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Delete Customer</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Do you want to delete this customer ?</Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-delete" fullWidth>
                loading
              </Button>
            ) : (
              <Button className="btn-delete" fullWidth onClick={handleDelete}>
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
