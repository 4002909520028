import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { UPDATE_NOTE } from "../../Schema/Project";

export default function UpdateNote({
  open,
  handleClose,
  setRefetch,
  editData,
  projectId,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [requiredField, setRequiredField] = useState(false);

  const newNoteData = {
    key: Date.now() + Math.random(),
    note: "",
  };

  const [noteListData, setNoteListData] = useState([newNoteData]);
  const handleAddNote = () => {
    setNoteListData([newNoteData, ...noteListData]);
  };

  const handleDeleteNote = (key) => {
    let deleteNote = noteListData?.filter((t) => t.key !== key);
    setNoteListData(deleteNote);
  };

  // ================= handle Update Socail Media ==================
  const setUpdateContact = (key, value) => {
    const newNote = noteListData?.map((e) => {
      if (e.key === key) e.note = value;
      return e;
    });
    setNoteListData([...newNote]);
  };

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted: ({ updateNote }) => {
      setLoading(false);
      if (updateNote?.status === true) {
        setAlert(true, "success", updateNote?.message);
        handleClose();
        resetForm();
        setRefetch();
        // setNoteListData([newNoteData]);
        // setRequiredField(false);
      } else {
        setAlert(true, "error", updateNote?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const validateSchema = Yup.object().shape({
    noteTitle: Yup.string().required("required"),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      noteTitle: "",
      description: "",
    },

    validationSchema: validateSchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      let createStatus = true;
      // const BreakException = {};
      // try {
      //   if (noteListData.length === 0) createStatus = true;
      //   if (noteListData.length !== 0) {
      //     noteListData.map((e) => {
      //       if (Boolean(e.note)) createStatus = true;
      //       else {
      //         createStatus = false;
      //         setRequiredField(true);
      //         setLoading(false);
      //         throw BreakException;
      //       }
      //     });
      //   }
      // } catch (e) {
      //   setLoading(false);
      //   if (e !== BreakException) throw e;
      // }

      if (createStatus) {
        const notesToSubmit = noteListData
          .filter((e) => {
            if (Boolean(e.note)) return e.note;
          })
          .map((i) => {
            return i.note;
          });
        // console.log("notesToSubmit::", notesToSubmit);
        setLoading(true);
        updateNote({
          variables: {
            id: editData?._id,
            input: {
              ...values,
              projectId: projectId,
              notes: notesToSubmit,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  // console.log("editData::", editData);
  useEffect(() => {
    setFieldValue("noteTitle", editData?.noteTitle);
    setFieldValue("description", editData?.description);

    if (editData?.notes?.length !== 0) {
      const newNote = editData?.notes?.map((e) => {
        return {
          key: Date.now() + Math.random(),
          note: e?.title,
        };
      });
      setNoteListData([...newNote]);
    }
  }, [editData]);

  return (
    <Dialog open={open} className="dialog-container">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Update note</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <Typography className="field-title">Note title</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="note title"
              {...getFieldProps("noteTitle")}
              error={Boolean(touched.noteTitle && errors.noteTitle)}
              helperText={touched.noteTitle && errors.noteTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="field-title">Description</Typography>
            <TextField
              fullWidth
              size="small"
              className="text-field"
              placeholder="Description"
              {...getFieldProps("description")}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ width: "80%" }}
                >
                  <Typography className="field-title">Notes</Typography>
                </Stack>
                <Button
                  className="btn-add"
                  endIcon={<AddCircleOutlineIcon className="add-icon" />}
                  onClick={handleAddNote}
                >
                  Add
                </Button>
              </Stack>

              <Stack
                direction="column"
                spacing={1.5}
                sx={{ marginTop: "15px" }}
              >
                {noteListData?.map((item) => {
                  return (
                    <Stack direction="row" key={item?.key} spacing={1}>
                      <TextField
                        sx={{ width: "80%" }}
                        size="small"
                        multiline
                        placeholder="input note"
                        value={item?.note}
                        onChange={(e) => {
                          setUpdateContact(item?.key, e.target.value);
                        }}
                        error={!Boolean(item?.contact) && requiredField}
                        helperText={
                          !Boolean(item?.contact) && requiredField
                            ? "required !"
                            : null
                        }
                      />
                      <Stack>
                        <IconButton onClick={() => handleDeleteNote(item?.key)}>
                          <DeleteIcon sx={{ color: "#f54949" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-create" fullWidth>
                {t("loading")}
              </Button>
            ) : (
              <Button className="btn-create" fullWidth onClick={handleSubmit}>
                {t("update")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
