import React, { useState } from "react";
import { Stack, IconButton, Box, Tooltip } from "@mui/material";
//Icons
import { MdOutlineGppGood } from "react-icons/md";
import { MdDelete, MdModeEdit } from "react-icons/md";
//Srcs
import DeleteQuotation from "./DeleteQuotation";
import UpdateQuotation from "./UpdateQuotation";
import UpdateQuotationStatus from "./UpdateQuotationStatus";

export default function QuotationAction({ editData, setRefetch }) {
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => setOpenStatus(false);

  return (
    <>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="Update" placement="top">
          <IconButton onClick={handleOpenUpdate}>
            <Box className="update-container">
              <MdModeEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Status" placement="top">
          <IconButton onClick={handleOpenStatus}>
            <Box className="status-container">
              <MdOutlineGppGood className="status-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <UpdateQuotation
        open={openUpdate}
        handleClose={handleCloseUpdate}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteQuotation
        open={openDelete}
        handleClose={handleCloseDelete}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UpdateQuotationStatus
        open={openStatus}
        handleClose={handleCloseStatus}
        editData={editData}
        setRefetch={setRefetch}
      />
    </>
  );
}
