import { gql } from "@apollo/client";

export const GET_SERVICE_FOR_SELECT = gql`
  query GetServicesForSelect {
    getServicesForSelect {
      _id
      price
      service
    }
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateService(
    $service: String!
    $unit: String!
    $remark: String
    $price: Float
    $serviceType: String
  ) {
    createService(
      service: $service
      unit: $unit
      remark: $remark
      price: $price
      serviceType: $serviceType
    ) {
      status
      message
    }
  }
`;

export const GET_SERVICE_WITH_PAGINATION = gql`
  query GetServicesPagination($page: Int!, $limit: Int!, $keyword: String!) {
    getServicesPagination(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        service
        serviceType
        price
        unit
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService(
    $id: ID!
    $service: String!
    $unit: String!
    $remark: String
    $price: Float
    $serviceType: String
  ) {
    updateService(
      _id: $id
      service: $service
      unit: $unit
      remark: $remark
      price: $price
      serviceType: $serviceType
    ) {
      status
      message
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(_id: $id) {
      status
      message
    }
  }
`;
