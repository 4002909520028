import React, { useState } from "react";
import {
  IconButton,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
//Icons
import { RiSubtractFill } from "react-icons/ri";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//Srcs
import "../../Style/dialogstyle.scss";
import { SelectOffer } from "../Include/DynamicSelect";

export default function FreeList({ freeListData, setFreeListData }) {
  const [offerVal, setOfferVal] = useState({ id: "", title: "" });
  // ================================  HANDLE ADD VALUES Free  ================================
  const handleAddFree = () => {
    setFreeListData([
      ...freeListData,
      { key: Date.now() + Math.random(), value: "" },
    ]);
  };
  // ================================ HANDLE UPDATE VALUES Free ==============================

  const handleUpdateValueFree = (key, value) => {
    const newFree = freeListData?.map((e) => {
      if (e.key === key) e.value = value;
      return e;
    });
    setFreeListData(newFree);
  };
  // ========================== HANDLE REMOVE Free ============================================
  const handleDeleteFree = (key) => {
    const newFree = freeListData.filter((t) => t.key !== key);
    setFreeListData(newFree);
  };

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between">
        <Typography className="field-title" fontStyle="italic" mt={1}>
          Free
        </Typography>
        <IconButton onClick={() => handleAddFree()}>
          <AddBoxOutlinedIcon sx={{ color: "#0f81c2" }} />
        </IconButton>
      </Stack>
      <Divider sx={{ bgcolor: "#0f81c2 !important" }} />
      <Stack direction={"column"} spacing={1} sx={{ marginTop: "10px" }}>
        {freeListData?.map((t) => {
          return (
            // <Stack direction="row" key={t?.key} spacing={1}>
            //   <TextField
            //     className="text-field"
            //     fullWidth
            //     size="small"
            //     placeholder="free"
            //     value={t.value}
            //     onChange={(e) =>
            //       handleUpdateValueFree(t?.key, e?.target?.value)
            //     }
            //   />
            //   <IconButton onClick={() => handleDeleteFree(t.key)}>
            //     <RiSubtractFill color="red" />
            //   </IconButton>
            // </Stack>

            <Stack direction="row" width="95%" key={t?.key} spacing={1}>
              <SelectOffer
                // requiredField={requiredField}
                selectedVal={t.value}
                setSelectedVal={setOfferVal}
                mainKey={t?.key}
                handleUpdateValueFree={handleUpdateValueFree}
              />
              <IconButton onClick={() => handleDeleteFree(t.key)}>
                <RiSubtractFill color="red" />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
