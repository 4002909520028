import React, { useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { UPDATE_SERVICE } from "../../Schema/Service";

export default function UpdateService({
  open,
  handleClose,
  setRefetch,
  editData,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [updateService] = useMutation(UPDATE_SERVICE, {
    onCompleted: ({ updateService }) => {
      setLoading(false);
      // console.log("updateService::", updateService);
      if (updateService?.status === true) {
        setAlert(true, "success", updateService?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateService?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });
  //fromik
  const CheckValidation = Yup.object().shape({
    service: Yup.string().required("require!"),
    serviceType: Yup.string(),
    unit: Yup.string().required("require!"),
    price: Yup.number().min(0).required("require!"),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      service: "",
      serviceType: "Storage",
      unit: "OneTime",
      price: "",
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values+", values);
      setLoading(true);
      updateService({
        variables: {
          id: editData?._id,
          ...values,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("service", editData?.service);
      setFieldValue("serviceType", editData?.serviceType);
      setFieldValue("unit", editData?.unit);
      setFieldValue("price", editData?.price);
      setFieldValue("remark", editData?.remark);
    }
  }, [editData]);

  // console.log("editData?.unit::", editData);

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Update Service</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">Service</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="service"
                  {...getFieldProps("service")}
                  error={Boolean(touched.service && errors.service)}
                  helperText={touched.service && errors.service}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Service type</Typography>
                <FormControl className="text-field" fullWidth size="small">
                  <Select
                    value={values?.serviceType}
                    onChange={(e) =>
                      setFieldValue("serviceType", e.target.value)
                    }
                  >
                    <MenuItem value="Storage">Storage</MenuItem>
                    <MenuItem value="Mobile App">Mobile App</MenuItem>
                    <MenuItem value="Web App">Web App</MenuItem>
                    <MenuItem value="Website">Website</MenuItem>
                    <MenuItem value="Media">Media</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Unit</Typography>
                <FormControl className="text-field" fullWidth size="small">
                  <Select
                    value={values?.unit}
                    onChange={(e) => setFieldValue("unit", e.target.value)}
                  >
                    <MenuItem value="OneTime">One Time</MenuItem>
                    <MenuItem value="Month">Month</MenuItem>
                    <MenuItem value="Year">Year</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Price</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="price"
                  type="number"
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{
                    // inputComponent: NumberFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    Loading
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
