import { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
//srcs
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { GET_SERVICE_FOR_SELECT } from "../../Schema/Service";
import { GET_CUSTOMER_FOR_SELECT } from "../../Schema/SelectSchema";
import { GET_PROJECT_FOR_SELECT } from "../../Schema/Project";
import { GET_TAX } from "../../Schema/Tax";
import { GET_ALL_OFFER } from "../../Schema/Offer";

//=============================== graphql ====================================

// ===================================  select School ========================================
export function SelectSchool({ selectedVal, setSelectedVal }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_SERVICE_FOR_SELECT, {
    onCompleted: ({ getServicesForSelect }) => {
      // console.log("getServicesForSelect::", getServicesForSelect);
      let rows = [];
      if (getServicesForSelect?.length !== 0) {
        getServicesForSelect?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.service,
          };
          rows.push(allRows);
          setSelectOptions(rows);
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? t("thead_select_value") : option.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
        />
      )}
    />
  );
}

// ===================================  select Customer ========================================
export function SelectCustomer({
  selectedVal,
  setSelectedVal,
  className,
  isSelected,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_CUSTOMER_FOR_SELECT, {
    onCompleted: ({ getCustomerForSelect }) => {
      let rows = [];
      if (getCustomerForSelect?.length !== 0) {
        getCustomerForSelect?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.customerName,
          };
          rows.push(allRows);
          setSelectOptions(rows);
          if (isSelected === "SELECTED") {
            setSelectOptions([{ id: "", title: "All Customer" }, ...rows]);
          }
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "All Customer" : option.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
        />
      )}
    />
  );
}

//============================================select service ============================================
export function SelectService({
  selectedVal,
  setSelectedVal,
  className,
  mainKey,
  handleUpdateValueMainItem,
  requiredField,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_SERVICE_FOR_SELECT, {
    onCompleted: ({ getServicesForSelect }) => {
      let rows = [];
      if (getServicesForSelect?.length !== 0) {
        getServicesForSelect?.map((d) => {
          if (Boolean(d?._id) && Boolean(d?.service)) {
            const allRows = {
              id: d?._id,
              title: `${d?.service} / ${d?.price} $`,
              price: d?.price,
            };
            rows.push(allRows);
            setSelectOptions(rows);
          }
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => {
        handleSelect(val);
        handleUpdateValueMainItem(mainKey, val, "SERVICE");
      }}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={(option) =>
        option?.title === undefined ? t("thead_select_value") : option?.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
          error={!Boolean(selectedVal?.id) && requiredField}
          helperText={
            !Boolean(selectedVal?.id) && requiredField ? "required !" : null
          }
        />
      )}
    />
  );
}

//============================================ select service ============================================
export function SelectTax({
  selectedVal,
  setSelectedVal,
  className,
  mainKey,
  handleUpdateValueMainItem,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_TAX, {
    onCompleted: ({ getTaxs }) => {
      let rows = [];
      if (getTaxs?.length !== 0) {
        getTaxs?.map((d) => {
          if (Boolean(d?._id) && Boolean(d?.taxName)) {
            const allRows = {
              id: d?._id,
              title: `${d?.taxName}(${d?.tax}%)`,
              price: d?.tax,
            };
            rows.push(allRows);
            setSelectOptions(rows);
          }
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => {
        handleSelect(val);
        handleUpdateValueMainItem(mainKey, val, "TAX");
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? t("thead_select_value") : option.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
          // error={!Boolean(selectedVal?.id)}
          // helperText={!Boolean(selectedVal?.id) ? "required" : null}
        />
      )}
    />
  );
}

// =================================== select Project ========================================
export function SelectProject({
  selectedVal,
  setSelectedVal,
  className,
  isSelected,
  disabled,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_PROJECT_FOR_SELECT, {
    onCompleted: ({ getProjectsForSelect }) => {
      let rows = [];
      if (getProjectsForSelect?.length !== 0) {
        getProjectsForSelect?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.projectName,
          };
          rows.push(allRows);
          setSelectOptions(rows);
          if (isSelected === "SELECTED") {
            setSelectOptions([{ id: "", title: "All Project" }, ...rows]);
          }
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disabled={disabled}
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => handleSelect(val)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "All Customer" : option.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
        />
      )}
    />
  );
}

// =================================== select Project ========================================
export function SelectOffer({
  selectedVal,
  setSelectedVal,
  className,
  mainKey,
  handleUpdateValueFree,
}) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  const { refetch } = useQuery(GET_ALL_OFFER, {
    onCompleted: ({ getOffers }) => {
      let rows = [];
      if (getOffers?.length !== 0) {
        getOffers?.map((d) => {
          const allRows = {
            id: d?._id,
            title: d?.description,
          };
          rows.push(allRows);
          setSelectOptions(rows);
        });
      } else {
        setSelectOptions([]);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      fullWidth
      className={className}
      value={selectedVal}
      options={selectOptions}
      onChange={(event, val) => {
        handleSelect(val);
        handleUpdateValueFree(mainKey, val);
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) =>
        option.title === undefined ? "All Offer" : option.title
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("label_select_value")}
        />
      )}
    />
  );
}
