import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { NumericFormat } from "react-number-format";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { SelectCustomer } from "../../Components/Include/DynamicSelect";
import { ADD_TRACKING_PAYMENT } from "../../Schema/Payment";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      onFocus={(e) => e.target.select()}
    />
  );
}

export default function CreatePayment({ open, handleClose, setRefetch }) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });

  const [addTrackingPayment] = useMutation(ADD_TRACKING_PAYMENT, {
    onCompleted: ({ addTrackingPayment }) => {
      setLoading(false);
      // console.log("addTrackingPayment::", addTrackingPayment);
      if (addTrackingPayment?.status === true) {
        setAlert(true, "success", addTrackingPayment?.message);
        handleClose();
        resetForm();
        // setRefetch();
      } else {
        setAlert(true, "error", addTrackingPayment?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    paymentName: Yup.string().required("require!"),
    paymentType: Yup.string().required("require!"),
    customer: Yup.string().required("require!"),
    price: Yup.string().required("require!"),
    expireAt: Yup.date(),
    alertFrom: Yup.date(),
    remark: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      paymentName: "",
      paymentType: "One Time",
      customer: "",
      price: "",
      expireAt: moment(),
      alertFrom: moment(),
      remark: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values+", values);
      setLoading(true);
      addTrackingPayment({
        variables: {
          input: {
            paymentName: values?.paymentName,
            paymentType: values?.paymentType,
            customer: values?.customer,
            price: values?.price,
            expireAt: moment(values?.expireAt).format("YYYY-MM-DD"),
            alertFrom: moment(values?.alertFrom).format("YYYY-MM-DD"),
            remark: values?.remark,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("customer", "");
    if (Boolean(customerVal?.id)) setFieldValue("customer", customerVal?.id);
  }, [customerVal?.id]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              Add tracking payment
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography className="field-title">Payment name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="payment name"
                  {...getFieldProps("paymentName")}
                  error={Boolean(touched.paymentName && errors.paymentName)}
                  helperText={touched.paymentName && errors.paymentName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Payment name</Typography>
                <FormControl className="select-role" fullWidth size="small">
                  <Select
                    value={values?.paymentType}
                    onChange={(e) =>
                      setFieldValue("paymentType", e.target.value)
                    }
                  >
                    <MenuItem value="One Time">One Time</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <Typography className="field-title">Customer</Typography>
                <SelectCustomer
                  selectedVal={customerVal}
                  setSelectedVal={setCustomerVal}
                />
                {!!errors.customer && touched.customer && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.customer}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Price</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="price"
                  name="NumericFormat"
                  id="formatted-numberformat-input"
                  onFocus={(e) => e.target.select()}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Expire at</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.expireAt}
                    onChange={(e) => setFieldValue("expireAt", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Alert from</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.alertFrom}
                    onChange={(e) => setFieldValue("alertFrom", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">Remark</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="remark"
                  {...getFieldProps("remark")}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    Loading
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
