import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Src
import "../../Style/dialogstyle.scss";
import EmptyImage from "../../Assets/empty-image.png";
import { AuthContext } from "../../Context/AuthContext";
import CropImageFile from "../CropImage/CropImageFile";
import { SelectCustomer } from "../Include/DynamicSelect";
import { CREATE_PROJECT } from "../../Schema/Project";

export default function CreateProject({ open, handleClose, setRefetch }) {
  // Change Language
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });

  const [createProject] = useMutation(CREATE_PROJECT, {
    onCompleted: ({ createProject }) => {
      setLoading(false);
      // console.log("createProject::", createProject);
      if (createProject?.status === true) {
        setAlert(true, "success", createProject?.message);
        handleClose();
        resetForm();
        setRefetch();
        setCustomerVal({ id: "", title: "" });
      } else {
        setAlert(true, "error", createProject?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const Validate = Yup.object().shape({
    projectName: Yup.string().required("require!"),
    customer: Yup.string().required("require!"),
    startDate: Yup.date(),
    endDate: Yup.date(),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      customer: "",
      description: "",
      startDate: moment(),
      projectName: "",
      endDate: moment(),
    },

    validationSchema: Validate,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createProject({
        variables: {
          input: {
            ...values,
          },
        },
      });
    },
  });

  useEffect(() => {
    setFieldValue("customer", "");
    if (Boolean(customerVal?.id)) setFieldValue("customer", customerVal?.id);
  }, [customerVal?.id]);

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Create Project</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">Title</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="project title"
                  {...getFieldProps("projectName")}
                  error={Boolean(touched.projectName && errors.projectName)}
                  helperText={touched.projectName && errors.projectName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">Start date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.startDate}
                    onChange={(newValue) =>
                      setFieldValue("startDate", newValue)
                    }
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">End date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.endDate}
                    onChange={(newValue) => setFieldValue("endDate", newValue)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Select customer</Typography>
                <SelectCustomer
                  selectedVal={customerVal}
                  setSelectedVal={setCustomerVal}
                />
                {!!errors.customer && touched.customer && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.customer}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">Description</Typography>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  className="text-field"
                  placeholder="description"
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
