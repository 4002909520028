import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import ReactToPrint from "react-to-print";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import { GrFormSubtract } from "react-icons/gr";
//Srcs
import "./previewquotation.scss";
import LogoReport from "../../Assets/Itlogo-report.png";
import WingdingImage from "../../Assets/tacteing.jpg";
import { currencyFormat } from "../../Function/DynamicFn";
import { GET_QUOTATION_BY_ID } from "../../Schema/Quotation";

export default function PreviewQuotation({ open, handleClose, quotationId }) {
  const [tableData, setTableData] = useState([]);
  const [itemData, setItemData] = useState([]);

  // console.log("quotationId::", quotationId)
  const { refetch } = useQuery(GET_QUOTATION_BY_ID, {
    variables: {
      id: quotationId,
    },
    onCompleted: ({ getQuotationDetail }) => {
      // console.log("getQuotationDetail::", getQuotationDetail);
      if (Boolean(getQuotationDetail)) {
        setTableData(getQuotationDetail);
        const newItem = getQuotationDetail?.items.map((item) => {
          const newItemsDes = item.itemsDes.map((e) => {
            return e;
          });
          return {
            itemeName: item?.itemeName,
            qty: item?.qty,
            unit: item?.unit,
            totalAmount: item?.totalAmount,
            tax: item?.tax,
            itemsDes: newItemsDes,
          };
        });
        setItemData(newItem);
      }
    },
    onError: (e) => {
      console.log(e.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [quotationId]);

  const subTotal = tableData?.items?.reduce((a, b) => a + b?.totalAmount, 0);
  const taxVal = tableData?.items?.reduce(
    (a, b) => a + (b?.tax * b?.totalAmount) / 100,
    0
  );
  // console.log("taxVal::", taxVal);

  const componentRef = useRef(null);
  const reactToPrintContent = () => {
    return componentRef.current;
  };

  const reactToPrintTrigger = () => {
    return (
      <Stack direction="row" justifyContent="right">
        <Button
          sx={{
            width: "20%",
            height: "38px",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#0f81c2 !important",
            color: "#fff",
          }}
        >
          Print
        </Button>
      </Stack>
    );
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {tableData?.project ?? ""}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box ref={componentRef} className="print-invoice-container">
              <Box>
                <Grid item container>
                  <Grid item xs={3}>
                    <Stack direction="row" justifyContent="center">
                      <img src={LogoReport} className="invoice-logo-image" />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column">
                      <Typography className="invoice-title-Report">
                        ព្រះរាជាណាចក្រកម្ពុជា
                      </Typography>
                      <Typography className="invoice-title-En">
                        Kingdom Of Cambodia
                      </Typography>
                      <Typography className="invoice-title-Report">
                        ជាតិ​ សាសនា ព្រះមហាក្សត្រ
                      </Typography>
                      <Typography className="invoice-title-En">
                        Nation Religion King
                      </Typography>
                      <div className="invoice-tackteng-font">
                        <img
                          alt="Image"
                          src={WingdingImage}
                          className="invoice-image-tackteng"
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>

                <Box sx={{ marginTop: "10px" }}>
                  <Grid container>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Grid item xs={4.5}>
                      <Stack direction="column">
                        <Stack direction="row" spacing={0.3}>
                          <Typography className="invoice-text-head-title-bold">
                            ឈ្មោះសហគ្រាស :
                          </Typography>
                          <Typography className="invoice-text-head-title-bold">
                            ហ្គោគ្លូប៊លអាយធី
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Stack direction="column" spacing={0.5}>
                            <Box className="invoice-text-head-title-bold">
                              អាស័យដ្ឋាន៖
                            </Box>
                          </Stack>
                          <Stack direction="column">
                            <Typography className="invoice-text-head-title">
                              ភូមិថ្មី សង្កាត់ស្វាយដង្គំ
                            </Typography>
                            <Typography className="invoice-text-head-title">
                              ក្រុងសៀមរាប ខេត្តសៀមរាប
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title">
                            Tel:
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            076 7772 168 / 085 70 55 55
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title">
                            VATTIN:
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            E116-2300000286
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <Stack direction="column" sx={{ marginTop: "-6px" }}>
                        <Typography className="invoice-title-kh">
                          សម្រង់តម្លៃ
                        </Typography>
                        <Typography className="invoice-title-en">
                          Quotation
                        </Typography>
                      </Stack>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }} />
                    <Grid item xs={4.5}>
                      <Stack direction="column">
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title-en-bold">
                            To :
                          </Typography>
                          <Typography className="invoice-text-head-title-bold">
                            {tableData?.to}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.4}>
                          <Typography className="invoice-text-head-title">
                            Date :
                          </Typography>
                          <Typography className="invoice-text-head-title">
                            {moment(tableData?.date).format("DD-MMM-YYYY")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Box sx={{ marginTop: "14px" }}>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack direction={"column"} className="header-invoice">
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack
                          width={"4%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            ល.រ
                          </Typography>
                        </Stack>
                        <Stack
                          width={"64%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            បរិយាយ
                          </Typography>
                        </Stack>
                        <Stack width={"16%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            បរិមាណ
                          </Typography>
                        </Stack>
                        <Stack
                          width={"16%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            សរុប
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack
                          width={"4%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            N°
                          </Typography>
                        </Stack>
                        <Stack
                          width={"64%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            Description
                          </Typography>
                        </Stack>
                        <Stack width={"16%"}>
                          <Typography
                            className="header-text-invioce"
                            sx={{ textAlign: "center" }}
                          >
                            Quantity
                          </Typography>
                        </Stack>
                        <Stack
                          width={"16%"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography className="header-text-invioce">
                            Total Amount
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "2px 0px" }} />
                    <Stack
                      direction={"column"}
                      spacing={0.6}
                      className="header-invoice"
                    >
                      {itemData?.map((e, index) => {
                        // console.log("e::", e)
                        return (
                          <Stack direction={"column"} spacing={0.5} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              className="invoice-des-en"
                            >
                              <Stack
                                width={"4%"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <Typography
                                  className="invoice-des"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  0{index + 1}
                                </Typography>
                              </Stack>
                              <Stack width={"64%"}>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Typography
                                    className="invoice-des"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {e?.itemeName}
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack width={"16%"}>
                                <Typography
                                  className="invoice-des"
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {e?.qty} {e?.unit}
                                </Typography>
                              </Stack>
                              <Stack
                                width={"16%"}
                                direction="row"
                                justifyContent="space-between"
                              >
                                {/* <Stack direction="row"> */}
                                  <Typography
                                    className="invoice-des"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    $
                                  </Typography>
                                  <Typography
                                    className="invoice-des"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {currencyFormat(e?.totalAmount)}
                                  </Typography>
                                {/* </Stack> */}
                              </Stack>
                            </Stack>

                            <Stack direction={"column"} spacing={0.5}>
                              {e?.itemsDes?.map((i, key) => (
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  key={key}
                                >
                                  <Stack width={"4%"}></Stack>
                                  <Stack width={"64%"} spacing={1}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      spacing={0.5}
                                    >
                                      <GrFormSubtract fontSize="10px" />
                                      <Typography className="invoice-des-in-des">
                                        {i}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Stack width={"32%"}></Stack>
                                  {/* <Stack width={"18%"}></Stack> */}
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        );
                      })}
                      {tableData?.free?.length !== 0 ? (
                        <>
                          <Stack direction={"column"} spacing={0.5}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              className="invoice-des-en"
                            >
                              <Stack
                                width={"4%"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              ></Stack>
                              <Stack width={"96%"}>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Typography
                                    className="invoice-des"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Free
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                          {tableData?.free?.map((i, key) => {
                            return (
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                key={key}
                              >
                                <Stack width={"4%"}></Stack>
                                <Stack width={"96%"} spacing={1}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={0.5}
                                  >
                                    <GrFormSubtract fontSize="10px" />
                                    <Typography className="invoice-des-in-des">
                                      {i?.description}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            );
                          })}
                        </>
                      ) : null}
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack
                      direction={"column"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack width={"84%"}>
                          <Stack direction={"column"}>
                            <Typography className="invoice-text-head-title-total">
                              សរុប($)
                            </Typography>
                            <Typography className="invoice-des-in-des">
                              Sub total ($)
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          width={"16%"}
                        >
                          <Stack direction="row">
                            <Typography className="invoice-total">$</Typography>
                            <Box flexGrow={1} />
                            <Typography
                              className="invoice-total"
                              sx={{ fontWeight: "bold" }}
                            >
                              {currencyFormat(subTotal)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack
                      direction={"column"}
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        pacing={3}
                      >
                        <Stack width={"84%"} spacing={3}>
                          <Stack direction={"column"}>
                            <Typography className="invoice-text-head-title-total">
                              អាករ ($)
                            </Typography>
                            <Typography className="invoice-des-in-des">
                              VAT ($)
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          width={"16%"}
                        >
                          <Stack direction="row">
                            <Typography className="invoice-total">$</Typography>
                            <Box flexGrow={1} />
                            <Typography
                              className="invoice-total"
                              sx={{ fontWeight: "bold" }}
                            >
                              {currencyFormat(taxVal)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                    <Stack
                      direction={"column"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack width={"84%"}>
                          <Stack direction={"column"}>
                            <Typography className="invoice-text-head-title-total">
                              សរុប($)
                            </Typography>
                            <Typography className="invoice-des-in-des">
                              Total ($)
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="column"
                          justifyContent="center"
                          width={"16%"}
                        >
                          <Stack direction="row">
                            <Typography className="invoice-total">$</Typography>
                            <Box flexGrow={1} />
                            <Typography
                              className="invoice-total"
                              sx={{ fontWeight: "bold" }}
                            >
                              {currencyFormat(tableData?.finalTotal)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider sx={{ margin: "4px 0px" }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={`${tableData?.project}-Quotation-${moment(
                tableData?.paymentDate
              ).format("DD-MMM-YYYY")}`}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
