import { gql } from "@apollo/client";

export const CREATE_QUOTATION = gql`
  mutation CreateQuotation($input: QuotationInput!) {
    createQuotation(input: $input) {
      status
      message
    }
  }
`;

export const GET_QUOTATION_WITH_PAGINATION = gql`
  query GetQuotationsPagination(
    $limit: Int!
    $page: Int!
    $project: ID!
    $customer: ID!
  ) {
    getQuotationsPagination(
      limit: $limit
      page: $page
      project: $project
      customer: $customer
    ) {
      data {
        _id
        numbering
        projectId
        project
        toId
        to
        status
        date
        finalTotal
        items {
          itemeId
          itemeName
          itemePrice
          taxName
          taxId
          tax
          qty
          totalAmount
          itemsDes
        }
        free {
          _id
          description
        }
        note
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_QUOTATION_BY_ID = gql`
  query GetQuotationDetail($id: ID!) {
    getQuotationDetail(_id: $id) {
      _id
      date
      numbering
      projectId
      project
      toId
      to
      status
      items {
        itemeId
        itemeName
        itemePrice
        taxName
        taxId
        tax
        qty
        unit
        totalAmount
        itemsDes
      }
      free {
        _id
        description
      }
      note
      tax
      finalTotal
    }
  }
`;

export const UPDATE_QUOTATION = gql`
  mutation UpdateQuotation($id: ID!, $input: QuotationInput!) {
    updateQuotation(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_QUOTATION_STATUS = gql`
  mutation UpdateQuotationStatus($id: ID!, $status: String!) {
    updateQuotationStatus(_id: $id, status: $status) {
      status
      message
    }
  }
`;

export const DELETE_QUOTATION = gql`
  mutation DeleteQuotation($id: ID!) {
    deleteQuotation(_id: $id) {
      status
      message
    }
  }
`;
