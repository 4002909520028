import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  TextField,
  Grid,
  InputAdornment,
  Button,
  MenuItem,
  Select,
  FormControl,
  Skeleton,
  Avatar,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "../Assets/create-icon.svg";
import { MdOutlineDateRange } from "react-icons/md";
//Src
import "./project.scss";
import CreateProject from "../Components/Project/CreateProject";
import ProjectAction from "../Components/Project/ProjectAction";
import FooterPagination from "../Components/Include/FooterPagination";
import { GET_PROJECT_WITH_PAGINATION } from "../Schema/Project";
import EmptyData from "../Components/Include/EmptyData";
import { SelectCustomer } from "../Components/Include/DynamicSelect";
import { currencyFormat } from "../Function/DynamicFn";

export default function Project() {
  const [payment, setPayment] = useState("All Payment");
  const [status, setStatus] = useState("All Status");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(16);
  const [customerVal, setCustomerVal] = useState({
    id: "",
    title: "All Customer",
  });
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`project-details?projectId=${id}`);
  };
  const colorStatus = (status) => {
    if (status === "Pending") return "#EBBC1D";
    if (status === "Not Start") return "#0F81C2";
    if (status === "In Progress") return "#14673C";
    if (status === "Testing") return "#EA2877";
    if (status === "Complete") return "#DA2328";
    if (status === "Maintenance") return "#EA2877";
    if (status === "Finished") return "#653516";
  };

  const colorPayment = (payment) => {
    if (payment === "Unpaid") return "#EBBC1D !important";
    if (payment === "Deposit") return "#0F81C2 !important";
    if (payment === "Complete") return "#14673C !important";
  };
  // console.log("customerVal?.id====>", customerVal?.id);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { refetch } = useQuery(GET_PROJECT_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      payment: payment,
      customer: Boolean(customerVal?.id) ? customerVal?.id : "All Customer",
      status: status,
    },
    onCompleted: ({ getProjectsPagination }) => {
      setTableData(getProjectsPagination?.data);
      setPaginationData(getProjectsPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      setTableData([]);
      console.log("Error:::::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [page, keyword, payment, status, customerVal?.id]);

  useEffect(() => {
    document.title = "Project"; // Set your dynamic title here
  }, []);

  return (
    <Box className="project-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>

        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Project</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="search-title">Search project</Typography>
            <TextField
              placeholder="search"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "#0f81c2" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="search-title">Filter by customer</Typography>
            <SelectCustomer
              selectedVal={customerVal}
              setSelectedVal={setCustomerVal}
              className={"search-field"}
              isSelected={"SELECTED"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2.4} xl={2.4}>
            <Typography className="search-title">Filter by status</Typography>
            <FormControl size="small" fullWidth className="search-field">
              <Select
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              >
                <MenuItem value={"All Status"}>All Status</MenuItem>
                <MenuItem value={"Pending"}>Pending </MenuItem>
                <MenuItem value={"Not Start"}>Not Start</MenuItem>
                <MenuItem value={"In Progress"}>In Progress</MenuItem>
                <MenuItem value={"Testing"}>Testing</MenuItem>
                <MenuItem value={"Complete"}>Complete</MenuItem>
                <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                <MenuItem value={"Finished"}>Finished</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={4.8} xl={4.8}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreateProject
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        {loading ? (
          <Grid container spacing={2}>
            {[1, 2, 3, 4, 5, 6, 7, 8]?.map((index1) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index1}>
                  <Box className="card-container">
                    <Stack
                      padding="5px 10px 0px 15px"
                      direction="column"
                      spacing={2}
                    >
                      <Stack direction="column" spacing={0.5}>
                        <Stack direction="row">
                          <Typography
                            className="status-card"
                            sx={{ color: "#F99A2D" }}
                          >
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                              width={120}
                            />
                          </Typography>
                          <Box flexGrow={1} />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem" }}
                            width={30}
                          />
                        </Stack>
                        <Stack direction="column" spacing={1}>
                          <Typography className="project-name">
                            <Skeleton variant="rectangular" width="78%" />
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Typography className="date">
                              <Skeleton variant="rectangular" width={90} />
                            </Typography>
                            <Typography className="total-price">
                              <Skeleton variant="rectangular" width={90} />
                            </Typography>
                          </Stack>
                          <Typography className="invoice-des">
                            <Skeleton variant="rectangular" width="78%" />
                          </Typography>
                        </Stack>
                      </Stack>
                      <Grid container>
                        <Grid item xs={5}>
                          <Stack direction="column" spacing={1}>
                            <Typography className="customer-title">
                              <Skeleton variant="rectangular" width="90%" />
                            </Typography>
                            <Typography className="customer-label">
                              <Skeleton variant="rectangular" width="90%" />
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={7}>
                          <Stack direction="column" spacing={1}>
                            <Typography className="customer-title">
                              <Skeleton variant="rectangular" width="90%" />
                            </Typography>
                            <Typography className="customer-label">
                              <Skeleton variant="rectangular" width="90%" />
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ) : tableData?.length === 0 ? (
          <Box
            sx={{
              marginTop: "10px",
              width: "100%",
              bgcolor: "#fff",
              borderRadius: "10px",
            }}
          >
            <EmptyData />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {tableData?.map((data, index) => {
              // console.log("data::", data);
              return (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                  <Box className="card-container">
                    <Stack
                      // padding="5px 10px 0px 15px"
                      direction="column"
                      spacing={2}
                    >
                      <Stack direction="column" spacing={0.5}>
                        <Stack direction="row">
                          <Typography
                            className="status-card"
                            sx={{ color: colorStatus(data?.status) }}
                          >
                            {data?.status}
                          </Typography>
                          <Box flexGrow={1} />
                          <ProjectAction editData={data} setRefetch={refetch} />
                        </Stack>
                        <Stack
                          direction="column"
                          spacing={1}
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleNavigate(data?._id)}
                        >
                          <Typography className="project-name">
                            {data?.projectName}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <MdOutlineDateRange className="icon-date" />
                            <Typography className="date">
                              {moment(data?.startDate).format("DD MMM YYYY")}
                            </Typography>
                            <Stack direction="column" justifyContent="center">
                              <Box className="line" />
                            </Stack>
                            <Typography className="total-price">
                              $ {currencyFormat(data?.price)}
                            </Typography>
                            <Stack direction="column" justifyContent="center">
                              <Box>/</Box>
                            </Stack>
                            <Typography
                              className={
                                data?.price === data?.payment
                                  ? "total-price"
                                  : "total-payment"
                              }
                            >
                              $ {currencyFormat(data?.payment)}
                            </Typography>
                          </Stack>
                          <Typography
                            className="invoice-des"
                            sx={{ height: 45 }}
                          >
                            {data?.description}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Grid
                        container
                        sx={{ cursor: "pointer" }}
                        onClick={handleNavigate}
                      >
                        <Grid item xs={8}>
                          <Stack direction="column" spacing={0.6}>
                            <Typography className="customer-title">
                              Customer
                            </Typography>
                            <Stack direction="row" spacing={0.4}>
                              <Stack direction="column" justifyContent="center">
                                <Avatar
                                  sx={{ width: 18, height: 18 }}
                                  alt={data?.customerName}
                                  // src={data?.customerProfile}
                                  src={`data:image/jpg;base64,${data?.customerProfile}`}
                                />
                              </Stack>
                              <Typography className="customer-label">
                                {data?.customerName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack direction="column" spacing={0.6}>
                            <Typography className="customer-title">
                              PAYMENT
                            </Typography>
                            <Typography
                              className="customer-label-status"
                              sx={{
                                color:
                                  data?.paymentStatus === "Paid"
                                    ? "#14673C"
                                    : "#EBBC1D",
                              }}
                            >
                              {data?.paymentStatus}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </Box>
  );
}
