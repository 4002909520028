import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      user {
        _id
        firstName
        lastName
        email
        userProfile
        role
      }
      token
      status
      message
    }
  }
`;
