import React, { useContext, useState, useEffect, useMemo } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//Date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import { SelectCustomer, SelectProject } from "../Include/DynamicSelect";
import ServiceList from "./ServiceList";
import PayConditionList from "./PayConditionList";
import { UPDATE_CONTRACT } from "../../Schema/Contract";

export default function UpdateContract({
  open,
  handleClose,
  setRefetch,
  editData,
}) {
  // Change Language
  const { language, setAlert } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [projectVal, setProjectVal] = useState({ id: "", title: "" });

  const [serviceListData, setServiceListData] = useState([
    { key: Date.now() + Math.random(), value: "" },
  ]);

  const [payConditionListData, setPayConditionListData] = useState([
    { key: Date.now() + Math.random(), value: "" },
  ]);

  const [updateContract] = useMutation(UPDATE_CONTRACT, {
    onCompleted: ({ updateContract }) => {
      setLoading(false);
      if (updateContract?.status === true) {
        setAlert(true, "success", updateContract?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updateContract?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const ValidatSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    project: Yup.string().required("required"),
    price: Yup.string().required("required"),
    startDate: Yup.date(),
    endDate: Yup.date(),
    customer: Yup.string().required("required"),
    customerName: Yup.string().required("required"),
    customerDate: Yup.date(),
    customerPosition: Yup.string().required("required"),
    referenceName: Yup.string().required("required"),
    referenceDate: Yup.date(),
    referencePosition: Yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      project: "",
      price: 0,
      startDate: moment(),
      endDate: moment(),
      customer: "",
      customerName: "",
      customerDate: moment(),
      customerPosition: "",
      referenceName: "",
      referenceDate: moment(),
      referencePosition: "",
    },

    validationSchema: ValidatSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const serviceDataToSubmit = serviceListData
        .filter((e) => {
          if (Boolean(e.value)) return e.value;
        })
        .map((i) => {
          return i.value;
        });

      const payConditionDataToSubmit = payConditionListData
        .filter((e) => {
          if (Boolean(e.value)) return e.value;
        })
        .map((i) => {
          return i.value;
        });
      // console.log("FreeValue::", FreeValue)
      updateContract({
        variables: {
          id: editData?._id,
          input: {
            title: values?.title,
            project: values?.project,
            price: values?.price,
            startDate: moment(values?.startDate).format("YYYY-MM-DD"),
            endDate: moment(values?.endDate).format("YYYY-MM-DD"),
            customer: values?.customer,
            customerName: values?.customerName,
            customerDate: moment(values?.customerDate).format("YYYY-MM-DD"),
            customerPosition: values?.customerPosition,
            referenceName: values?.referenceName,
            referenceDate: moment(values?.referenceDate).format("YYYY-MM-DD"),
            referencePosition: values?.referencePosition,
            services: serviceDataToSubmit,
            payCondition: payConditionDataToSubmit,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (projectVal?.id) {
      setFieldValue("project", projectVal?.id);
    } else {
      setFieldValue("project", "");
    }
  }, [projectVal?.id]);

  useEffect(() => {
    if (customerVal?.id) {
      setFieldValue("customer", customerVal?.id);
    } else {
      setFieldValue("customer", "");
    }
  }, [customerVal?.id]);

  // console.log("editData::", editData);
  //============================ UPDATE INVOICE ======================================
  useEffect(() => {
    if (editData) {
      setFieldValue("title", editData?.title);
      setFieldValue("customer", editData?.customerId);
      setFieldValue("customerName", editData?.customerName);
      setFieldValue("price", editData?.price);
      setFieldValue("startDate", editData?.startDate);
      setFieldValue("endDate", editData?.endDate);
      setFieldValue("customerDate", editData?.customerDate);
      setFieldValue("customerPosition", editData?.customerPosition);
      setFieldValue("referenceName", editData?.referenceName);
      setFieldValue("referenceDate", editData?.referenceDate);
      setFieldValue("referencePosition", editData?.referencePosition);
      setFieldValue("project", editData?.projectId);
      setProjectVal({
        id: editData?.projectId,
        title: editData?.projectName,
      });

      setCustomerVal({
        id: editData?.customerId,
        title: editData?.customerName,
      });
    }

    if (Boolean(editData?.services)) {
      const newService = editData?.services?.map((e) => {
        return {
          key: Date.now() + Math.random(),
          value: e,
        };
      });
      setServiceListData(newService);
    }

    if (Boolean(editData?.payCondition)) {
      const newPayCondition = editData?.payCondition?.map((e) => {
        return {
          key: Date.now() + Math.random(),
          value: e,
        };
      });
      setPayConditionListData(newPayCondition);
    }

    //============================== SERVICE LIST DATA ======================================
  }, [editData, open]);

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="md">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">Update Contract</Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={4}>
                <Typography className="field-title">Title</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="title"
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">Select project</Typography>
                <SelectProject
                  selectedVal={projectVal}
                  setSelectedVal={setProjectVal}
                />
                {!!errors.project && touched.project && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.project}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={2}>
                <Typography className="field-title">Price</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="title"
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Customer</Typography>
                <SelectCustomer
                  selectedVal={customerVal}
                  setSelectedVal={setCustomerVal}
                />
                {!!errors.customer && touched.customer && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.customer}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Start date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.startDate}
                    onChange={(e) => setFieldValue("startDate", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">End date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.endDate}
                    onChange={(e) => setFieldValue("endDate", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Reference name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="reference name"
                  {...getFieldProps("referenceName")}
                  error={Boolean(touched.referenceName && errors.referenceName)}
                  helperText={touched.referenceName && errors.referenceName}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Reference date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.referenceDate}
                    onChange={(e) => setFieldValue("referenceDate", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">
                  Reference positions
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="reference positions"
                  {...getFieldProps("referencePosition")}
                  error={Boolean(
                    touched.referencePosition && errors.referencePosition
                  )}
                  helperText={
                    touched.referencePosition && errors.referencePosition
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">
                  Customer reference
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="customer reference"
                  {...getFieldProps("customerName")}
                  error={Boolean(touched.customerName && errors.customerName)}
                  helperText={touched.customerName && errors.customerName}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">Customer date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd-MM-yyyy"
                    value={values?.customerDate}
                    onChange={(e) => setFieldValue("customerDate", e)}
                    className="search-field"
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography className="field-title">
                  Customer positions
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="customer positions"
                  {...getFieldProps("customerPosition")}
                  error={Boolean(
                    touched.customerPosition && errors.customerPosition
                  )}
                  helperText={
                    touched.customerPosition && errors.customerPosition
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <ServiceList
                  serviceListData={serviceListData}
                  setServiceListData={setServiceListData}
                />
              </Grid>

              <Grid item xs={12}>
                <PayConditionList
                  payConditionListData={payConditionListData}
                  setPayConditionListData={setPayConditionListData}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("update")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
