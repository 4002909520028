import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import CreateIcon from "../Assets/create-icon.svg";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import FooterPagination from "../Components/Include/FooterPagination";
// import CreateUser from "../Components/User/CreateUser";
// import UserAction from "../Components/User/UserAction";
import CreatePayment from "../Components/Payment/CreatePayment";
import { GET_USER_WITH_PAGINATION } from "../Schema/User";

export default function Payment() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [role, setRole] = useState("All");

  //   GET_TRACKING_PAYMENT_WITH_PAGINATION
  // getTrackingPaymentsPagination

  const { refetch } = useQuery(GET_USER_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      role: role,
    },
    onCompleted: ({ getUsersPagination }) => {
      // console.log(
      //   "getUsersPagination",
      //   getUsersPagination
      // );
      setTableData(getUsersPagination?.data);
      setPaginationData(getUsersPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const handleSearch = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [open]);

  // ============================ Resize width Screen =================================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    document.title = "Payment"; // Set your dynamic title here
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">PAYMENT</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">Search username</Typography>
            <TextField
              placeholder="search"
              size="small"
              className="search-field"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "#0f81c2" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                Create
              </Button>
              <CreatePayment
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableRow>
                <TableCell className="header-title-start" width="4%">
                  N°
                </TableCell>
                <TableCell className="header-title" width="15%">
                  User
                </TableCell>
                <TableCell className="header-title" width="10%">
                  Role
                </TableCell>
                <TableCell className="header-title" width="20%">
                  Email
                </TableCell>
                <TableCell className="header-title-end" width="41%"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow className="body">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <TableBody className="body">
                      {tableData?.map((row, index) => {
                        return (
                          <TableRow className="body-row" key={index}>
                            <TableCell className="body-cell-start">
                              {index + paginationData?.slNo}
                            </TableCell>
                            <TableCell className="body-cell">
                              <Stack direction="row" spacing={1.4}>
                                <Stack
                                  direction="column"
                                  justifyContent="center"
                                >
                                  <Avatar
                                    sx={{ width: 40, height: 40 }}
                                    alt={row?.first_name}
                                    src={`data:image/jpg;base64,${row?.userProfile}`}
                                  />
                                </Stack>
                                <Stack
                                  direction="column"
                                  justifyContent="center"
                                >
                                  <Typography className="user-name">
                                    {row?.lastName + " " + row?.firstName}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.role}
                            </TableCell>
                            <TableCell className="body-cell">
                              {row?.email}
                            </TableCell>
                            <TableCell className="body-cell-end" align="right">
                              {/* <UserAction editData={row} setRefetch={refetch} /> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
