import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { string, ref } from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { useMutation } from "@apollo/client";
import imageCompression from "browser-image-compression";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
//Src
import "../../Style/dialogstyle.scss";
import EmptyImage from "../../Assets/empty-image.png";
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_USER } from "../../Schema/User";
import { translateLauguage } from "../../Function/Translate";
import CropImageFile from "../CropImage/CropImageFile";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CreateUser({ open, handleClose, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // hide password hook
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  // ======================= Progress ==============================
  const [statusProgress, setStatusProgress] = useState(false);
  const [progress, setProgress] = useState(10);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // ===================== End progress =============================

  const handleUploadImage = async (e) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    var newFile = new File([compressedFile], `${imageFile.name}.png`, {
      type: "image/png",
    });
    // console.log("imageFile::", imageFile);
    // console.log("newFile::", newFile);

    if (newFile) {
      setImageFile(newFile);
      setPhotoURL(URL.createObjectURL(newFile));
      setOpenCrop(true);
    }
  };

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      setLoading(false);
      // console.log("createUser::", createUser);
      if (createUser?.status === true) {
        setAlert(true, "success", createUser?.message);
        handleClose();
        resetForm();
        setRefetch();
        setProfileHook("");
      } else {
        setAlert(true, "error", createUser?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const Validate = Yup.object().shape({
    firstName: Yup.string().required("require!"),
    lastName: Yup.string().required("require!"),
    email: Yup.string().email().required("require!"),
    password: Yup.string()
      .required("required!")
      .min(8, "Please input more then 8 charactor!"),
    confirmPassword: string()
      .required("require!")
      .oneOf([ref("password")], "Wrong password!"),
    role: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "admin",
    },

    validationSchema: Validate,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createUser({
        variables: {
          input: {
            email: values?.email,
            firstName: values?.firstName,
            lastName: values?.lastName,
            password: values?.password,
            role: values?.role,
            userProfile: profileHook,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              {t("modal_title_create_user")}
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <Button component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : EmptyImage
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setProgress={setProgress}
                    setStatusProgress={setStatusProgress}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}

                {statusProgress && progress < 100 ? (
                  <Box sx={{ width: "100%", marginLeft: "20px" }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                ) : (
                  <Typography
                    className="field-title"
                    sx={{ textAlign: "center", marginTop: "5px" }}
                  >
                    Profile image
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography className="field-title">First name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="first name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="field-title">Last name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="last name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography className="field-title">Select role</Typography>
                <FormControl className="text-field" fullWidth size="small">
                  <Select
                    value={values?.role}
                    onChange={(e) => setFieldValue("role", e.target.value)}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="superAdmin">SuperAdmin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <Typography className="field-title">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="example@gmail.com"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_password")}
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  type={show ? "text" : "password"}
                  placeholder={t("label_password")}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="viewpw-icon" />
                        ) : (
                          <VisibilityOffIcon className="viewpw-icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-title">
                  {t("thead_confirmpassword")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type={show ? "text" : "password"}
                  placeholder={t("label_confirmpassword")}
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    {t("loading")}
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={statusProgress && progress < 100 ? true : false}
                  >
                    {t("create")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
