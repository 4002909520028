import { gql } from "@apollo/client";

export const CREATE_CONTRACT = gql`
  mutation CreateContract($input: ContractInput!) {
    createContract(input: $input) {
      status
      message
    }
  }
`;

export const GET_CONTRACT_WITH_PAGINATION = gql`
  query GetContractsPagination(
    $page: Int!
    $limit: Int!
    $customer: ID!
    $project: ID!
    $status: String!
    $keyword: String
  ) {
    getContractsPagination(
      page: $page
      limit: $limit
      customer: $customer
      project: $project
      status: $status
      keyword: $keyword
    ) {
      data {
        _id
        title
        startDate
        endDate
        projectId
        projectName
        customerId
        customerName
        status
        services
        price
        customerAddress
        payCondition
        referenceName
        referencePosition
        referenceDate
        customerPosition
        customerDate
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_CONTRACT_DETAILS = gql`
  query GetContractDetail($id: ID!) {
    getContractDetail(_id: $id) {
      title
      startDate
      endDate
      project
      customer
      services
      payCondition
      referenceName
      referencePosition
      referenceDate
      customerPosition
      customerDate
      customerAddress
      customerName
      price
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract($id: ID!, $input: ContractInput!) {
    updateContract(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_CONTRACT = gql`
  mutation DeleteContract($id: ID!) {
    deleteContract(_id: $id) {
      status
      message
    }
  }
`;
