import React, { useState, useRef, useEffect } from "react";
//Dialog
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import ReactToPrint from "react-to-print";
//Srcs
import "./printcontract.scss";
import LogoReport from "../../Assets/Itlogo-report.png";
import WingdingImage from "../../Assets/tacteing.jpg";
import {
  getKhmerDay,
  getKhmerMonth,
  getKhmerNumber,
} from "../../Function/DynamicFn";
import { GET_CONTRACT_DETAILS } from "../../Schema/Contract";

export default function PrintContract({ open, handleClose, contractId }) {
  const [printData, setPrintData] = useState();
  const componentRef = useRef(null);
  const reactToPrintContent = () => {
    return componentRef.current;
  };

  const { refetch } = useQuery(GET_CONTRACT_DETAILS, {
    variables: {
      id: contractId,
    },
    onCompleted: ({ getContractDetail }) => {
      // console.log("getContractDetail::", getContractDetail);
      setPrintData(getContractDetail);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [contractId]);

  const monthDifference = moment(new Date(printData?.endDate)).diff(
    new Date(printData?.startDate),
    "days",
    true
  );

  // console.log("monthDifference::", monthDifference)

  const reactToPrintTrigger = () => {
    return (
      <Button
        sx={{
          width: "20%",
          height: "38px",
          padding: "6px",
          borderRadius: "10px",
          backgroundColor: "#0f81c2 !important",
          color: "#fff",
        }}
      >
        Print
      </Button>
    );
  };

  return (
    <div>
      <Dialog open={open} className="print-contract" fullWidth maxWidth="md">
        <DialogTitle sx={{ padding: "10px 20px" }}>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" justifyContent="center">
              <Typography className="dialog-title">
                Preview {printData?.title}
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <table className="print-contract-container" ref={componentRef}>
            {/* <thead className="contract-header">
              <tr>
                <th className="contract-header-cell">
                  <div className="header-info">header content....</div>
                </th>
              </tr>
            </thead> */}
            <tfoot className="contract-footer">
              <tr>
                <td className="contract-footer-cell">
                  <div className="contract-footer-info">
                    <div className={"contract-page-footer"}>
                      <Divider className="divider" />
                      <div
                        className="page-footer-text-main"
                        style={{ marginTop: "-2px" }}
                      >
                        ភូមិថ្មី សង្កាត់ស្វាយដង្គំ ក្រុងសៀមរាប ខេត្តសៀមរាប
                        ព្រះរាជាណាចក្រកម្ពុជា/Thmey Village, Svay Dangkum, Siem
                        Reap, Cambodia.
                      </div>
                      <div
                        className={"page-footer-text-main"}
                        style={{ marginTop: "-8px" }}
                      >
                        ទូរស័ព្ទៈ 012 660 941/E-mail: goglobalit2022@gmail.com
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
            <tbody className="contract-content">
              <tr>
                <td className="contract-content-cell">
                  <div className="main">
                    <div style={{ paddingTop: "60px" }}>
                      <Grid item container>
                        <Grid item xs={3}>
                          <Stack direction="column">
                            <Stack direction="row" justifyContent="right">
                              <img
                                src={LogoReport}
                                className="contract-logo-image"
                              />
                            </Stack>
                            {/* <Typography className="contract-logo-title">
                      លេខ​៖ .................
                    </Typography> */}
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction="column">
                            <Typography className="contract-title-Report">
                              ព្រះរាជាណាចក្រកម្ពុជា
                            </Typography>
                            <Typography className="contract-title-En">
                              Kingdom Of Cambodia
                            </Typography>
                            <Typography className="contract-title-Report">
                              ជាតិ​ សាសនា ព្រះមហាក្សត្រ
                            </Typography>
                            <Typography className="contract-title-En">
                              Nation Religion King
                            </Typography>
                            <Stack direction="row" justifyContent="center">
                              <img
                                alt="Image"
                                src={WingdingImage}
                                className="contract-image-tackteng"
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>

                      <Stack
                        direction="column"
                        spacing={0.4}
                        sx={{ marginTop: "20px" }}
                      >
                        <Typography className="contract-title-Report">
                          កិច្ចព្រមព្រៀងផ្គត់ផ្គង់សេវាកម្ម
                        </Typography>
                        <Typography className="contract-title-Report">
                          {printData?.project}
                        </Typography>
                        <Typography className="contract-title-Report">
                          “សម្រាប់ {printData?.customer}”
                        </Typography>
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={0.7}
                        sx={{ marginTop: "20px", padding: "0px 60px 0px 70px" }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            កិច្ចព្រមព្រៀងនេះធ្វើឡើង
                          </Typography>
                          <Typography className="contract-body-des">
                            និងមានប្រសិទ្ធភាពរយៈពេល
                          </Typography>
                          <Typography className="contract-body-des">
                            {monthDifference.toString().length === 1
                              ? "0" + monthDifference
                              : monthDifference}{" "}
                            ថ្ងៃ ចាប់ពីថ្ងៃទី{" "}
                            {getKhmerNumber(
                              moment(printData?.startDate).format("DD")
                            )}{" "}
                            {getKhmerMonth(
                              moment(printData?.startDate).format("M")
                            )}{" "}
                            ឆ្នាំ
                            {getKhmerNumber(
                              moment(printData?.startDate).format("YYYY")
                            )}{" "}
                            ដល់ថ្ងៃ
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ទី{" "}
                          {getKhmerNumber(
                            moment(printData?.endDate).format("DD")
                          )}{" "}
                          {getKhmerMonth(
                            moment(printData?.endDate).format("M")
                          )}{" "}
                          ឆ្នាំ
                          {getKhmerNumber(
                            moment(printData?.endDate).format("YYYY")
                          )}
                          ។
                        </Typography>
                        <Typography className="contract-body-des-bold-center">
                          រវាង
                        </Typography>

                        <span>
                          <span
                            className="contract-body-des-moul"
                            style={{ paddingLeft: "44px" }}
                          >
                            ក្រុមហ៊ុនហ្គោគ្លូប៊លអាយធី​{" "}
                          </span>
                          <span className="contract-body-des">
                            មានអាសយដ្ឋានស្ថិតនៅ​{" "}
                          </span>
                          <span className="contract-body-des">
                            ភូមិថ្មី​ ក្រុងសៀមរាប ខេត្តសៀមរាប តំណាងដោយ
                          </span>
                          <span className="contract-body-des">តំណាងដោយ​</span>
                          <span className="contract-body-des">
                            {" "}
                            លោក ឡុក ឡងឌី{" "}
                          </span>
                          <span className="contract-body-des">មានតួនាទីជា</span>
                          <span className="contract-body-des">
                            {" "}
                            អគ្គនាយកក្រុមហ៊ុន{" "}
                          </span>
                          <span className="contract-body-des">
                            ហៅកាត់ភាគី “ក” ។
                          </span>
                        </span>

                        <span>
                          <span
                            className="contract-body-des-moul"
                            style={{ paddingLeft: "44px" }}
                          >
                            {printData?.customer}{" "}
                          </span>
                          <span className="contract-body-des">
                            មានអាសយដ្ឋានស្ថិតនៅ
                          </span>
                          <span className="contract-body-des">
                            {printData?.customerAddress}
                          </span>
                          <span className="contract-body-des">តំណាងដោយ</span>
                          <span className="contract-body-des">
                            {" "}
                            ({printData?.customerName}){" "}
                          </span>
                          <span className="contract-body-des">មានតួនាទីជា</span>
                          <span className="contract-body-des">
                            {" "}
                            ({printData?.customerPosition}){" "}
                          </span>
                          <span className="contract-body-des">
                            ហៅកាត់ភាគី “ខ” ។
                          </span>
                        </span>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ភាគីទាំងពីរបានយល់ស្រប
                          </Typography>
                          <Typography className="contract-body-des">
                            និងទទួលស្គាល់ការសន្យារវាងគ្នាទៅវិញទៅមក​
                          </Typography>
                          <Typography className="contract-body-des">
                            និងយល់ព្រមចុះកិច្ចព្រមព្រៀង
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ដែលមានលក្ខខណ្ឌដូចខាងក្រោម ៖
                        </Typography>
                        {/* <Typography className="contract-body-des-bold-center">
                  សេវាកម្មគ្រប់គ្រង
                </Typography> */}
                        {printData?.services?.map((row, index) => {
                          return (
                            <span
                              className="contract-body-des"
                              style={{ paddingLeft: "44px" }}
                              key={index}
                            >
                              - {row}
                            </span>
                          );
                        })}
                        <Typography className="contract-body-des-bold">
                          ប្រការ ១៖ ការផ្តល់សិទ្ធ
                        </Typography>
                        <span>
                          <span
                            className="contract-body-des-bold"
                            style={{ paddingLeft: "44px" }}
                          >
                            ភាគី “ខ”
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            បានអនុញ្ញាតឲ្យ
                          </span>
                          <span className="contract-body-des-bold">
                            {" "}
                            ភាគី “ក”
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            បម្រើសេវាកម្មខាងលើ{" "}
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            ចាំពីថ្ងៃចុះកិច្ចនេះតទៅ។
                          </span>
                        </span>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            និងប្រគល់ជូន
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            រាល់ឯកសារ
                          </Typography>
                          <Typography className="contract-body-des">
                            របាយការណ៍ពាក់ព័ន្ធនឹងសេវាកម្មដែលបានផ្គត់ផ្គង់ជា
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            កម្មសិទ្ធិផ្តាច់មុខរបស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”។
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ២៖ ការរក្សាសិទ្ធិ
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            យល់ព្រមប្រគល់ឯកសារនានាមក
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            សម្រាប់ប្រើប្រាស់
                          </Typography>
                          <Typography className="contract-body-des">
                            សុទ្ធសឹងតែជាកម្មសិទ្ធិបញ្ញា និងអាច
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ប្រើប្រាស់បានដោយមិនប៉ះពាល់ដល់ជំនួញ
                          ឬក៏សិទ្ធិរបស់អ្នកដទៃ។
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ធានាថា ព័ត៌មាន
                            ឯកសារផ្សេងៗដែលបានប្រើប្រាស់សុទ្ធសឹងតែជាកម្មសិទ្ធិបញ្ញា
                            និងជាការសម្ងាត់
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            ដោយមិនធ្វើឱ្យប៉ះពាល់ដល់ជំនួញ ឬក៏សិទ្ធិរបស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ទេ។
                          </Typography>
                        </Stack>
                        {/* <Stack direction="row" spacing={2} sx={{ paddingLeft: "44px" }}>
                  <Typography className="contract-body-des">
                    នៅក្នុងកិច្ចព្រមព្រៀងនេះ
                  </Typography>
                  <Typography className="contract-body-des-bold">
                    ភាគី “ខ”
                  </Typography>
                  <Typography className="contract-body-des">
                    មិនទទួលបានកម្មសិទ្ធិណាមួយរបស់ កម្មវិធី ឯកសារ ឧបករណ៍
                  </Typography>
                </Stack>
                <Typography className="contract-body-des">
                  បច្ចេកទេស វិធីសាស្ត្រ
                  ឬសម្ភារៈផ្សេងទៀតដែលមិនត្រូវបានបង្កើតឡើងជាផ្នែកនៃសេវាកម្មផ្គត់ផ្គង់
                  និងជាកម្មសិទ្ធិបញ្ញា
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Typography className="contract-body-des">របស់</Typography>
                  <Typography className="contract-body-des-bold">
                    ភាគី “ក” ។
                  </Typography>
                </Stack> */}
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៣៖ លក្ខខណ្ឌក្នុងអំឡុងការផ្តល់សេវាកម្ម
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            និង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬក៏បុគ្គលណាដែលបានតែងតាំងដោយ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ត្រូវតែធ្វើការសហការគ្នាដូចជាការ
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            ផ្តល់ព័ត៌មាន ឯកសារ ឬក៏បញ្ហាពាក់ព័ន្ធផ្សេងៗដែល
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ត្រូវការក្នុងការបង្កើត ថែទាំ ឬអភិវឌ្ឍកម្មវិធី។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ធានាថាសេវាកម្មនឹងត្រូវបានអនុវត្តប្រកបដោយវិជ្ជាជីវៈ
                          </Typography>
                          <Typography className="contract-body-des">
                            និងទាន់ពេលវេលា
                          </Typography>
                          <Typography className="contract-body-des">
                            ហើយមិនកេងផល
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            ប្រយោជន៍ ឬសក្ដានុពលណាមួយដែលនាំមកនូវផលអវិជ្ជមានដល់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះទេ។
                          </Typography>
                        </Stack>

                        {/* ============================================== Second page ================================================ */}
                        {/* <table className="document-container-main"></table> */}
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px", paddingTop: "5px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងទទួលខុសត្រូវចំពោះការរំលោភបំពានលើការធានា
                          </Typography>
                          <Typography className="contract-body-des">
                            និងអនុវត្តឡើងវិញនូវសេវាកម្មដែលខ្វះខាត
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ក្នុងករណីណាមួយដែល
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            មិនអាច
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬបរាជ័យក្នុងការកែតម្រូវចំណុចខ្វះខាតនោះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            អាចបញ្ចប់
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            កិច្ចព្រមព្រៀងនេះ
                          </Typography>
                          <Typography className="contract-body-des">
                            ដូចដែលមានចែងក្នុង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ប្រការ​ ៦។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងមិនទទួលខុសត្រូវចំពោះការយឺតយ៉ាវក្នុងការផ្តល់សេវាកម្មផ្នែកណាមួយដែលកើតឡើង
                          </Typography>
                          <Typography className="contract-body-des">
                            ដោយ
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            សារការខកខានរបស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ក្នុងការបំពេញភារកិច្ច
                          </Typography>
                          <Typography className="contract-body-des">
                            និងឆ្លើយតបទៅកាន់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ឱ្យបានទាន់ពេលវេលានោះ
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ឡើយ។
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            វិធីសាស្រ្ត
                          </Typography>
                          <Typography className="contract-body-des">
                            និងព័ត៌មានលម្អិតនៃការអនុវត្តសេវាកម្មនឹងត្រូវបានកំណត់ដោយ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”។
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងប្រើប្រាស់បុគ្គលិកផ្ទាល់ខ្លួន
                          </Typography>
                          <Typography className="contract-body-des">
                            ដើម្បីអនុវត្តសេវាកម្មក្រោមកិច្ចព្រមព្រៀងនេះ។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគីទាំងពីរ
                          </Typography>
                          <Typography className="contract-body-des">
                            បានទទួលស្គាល់ថា
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងអនុវត្តសេវាកម្មក្នុងនាមជាអ្នកផ្តល់សេវាកម្ម
                          </Typography>
                          <Typography className="contract-body-des">
                            មិនមែនជាបុគ្គលិក
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            របស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ទេ។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            រាល់កិច្ចព្រមព្រៀងនេះនឹងមិនចាត់ទុកថាបានបង្កើតទីភ្នាក់ងារ
                          </Typography>
                          <Typography className="contract-body-des">
                            ភាពជាដៃគូ
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬការបណ្ដាក់ទុនរួមគ្នារវាងភាគី
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ណាមួយឡើយ។
                        </Typography>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៤៖ ការបង់ប្រាក់
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងធ្វើការទូទាត់ទៅ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            សម្រាប់ការចំណាយណាមួយដែលចាំបាច់ក្នុងការផ្តល់សេវាកម្មដូចជា
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ថ្លៃធ្វើដំណើរក្នុងសេវាកម្មបណ្ដុះបណ្ដាលអាស្រ័យទៅតាមទីកន្លែងរបស់អតិថិជន។
                        </Typography>
                        <span>
                          <span
                            className="contract-body-des-bold"
                            style={{ paddingLeft: "44px" }}
                          >
                            ភាគី “ក”
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            បានព្រមព្រៀងជាមួយ
                          </span>
                          <span className="contract-body-des-bold">
                            ភាគី “ខ”
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            ក្នុងការផ្តល់សេវាកម្ម
                          </span>
                          <span
                            className="contract-body-des"
                            sx={{ bgcolor: "yellow" }}
                          >
                            {" "}
                            ({printData?.project}){" "}
                          </span>
                          <span className="contract-body-des">ក្នុងតម្លៃ</span>
                          <span
                            className="contract-body-des"
                            sx={{ bgcolor: "yellow" }}
                          >
                            {" "}
                            ({printData?.price} ដុល្លា){" "}
                          </span>
                          <span className="contract-body-des">
                            {" "}
                            ដែលអនុវត្តតាមលក្ខខណ្ឌដូចតទៅ៖{" "}
                          </span>
                        </span>
                        {printData?.payCondition?.map((row, index) => {
                          return (
                            <span
                              className="contract-body-des"
                              style={{ paddingLeft: "44px" }}
                              key={index}
                            >
                              - {row}
                            </span>
                          );
                        })}
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ចំណាំ៖
                          </Typography>
                          <Typography className="contract-body-des">
                            សម្រាប់សំណើបន្ថែមណាមួយដែលលើសពីវិសាលភាពនៃការងារ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងប៉ាន់ប្រមាណរយៈ
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            ពេលដល់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី​ “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ក្នុងការទូទាត់បន្ថែម និងការយល់ព្រម
                            មុននឹងបន្តការងារទៅទៀត។
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៥៖ វិក្កយបត្រហួសកាលកំណត់
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            បន្ថែមពីលើការទូទាត់យឺតយ៉ាវណាមួយដែលបណ្ដាលមកពី
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងមានកាតព្វកិច្ច
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ទូទាត់ថ្លៃសេវាទៅកាន់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នៃតម្លៃដែលត្រូវបង់ក្នុងរយៈពេលដប់ថ្ងៃបន្ទាប់ពីកាលបរិចេ្ឆទចេញវិក្កយបត្រ
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងបូក
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          បន្ថែមអត្រា ៥% នៃតម្លៃសេវាកម្មទាំងមូល។
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ប្រសិនបើ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ជ្រើសរើសបញ្ឈប់ប្រើប្រាស់សេវាកម្ម
                          </Typography>
                          <Typography className="contract-body-des">
                            ហើយមិនបង់ថ្លៃសេវាដែលនៅសេសសល់
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះនឹង
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ត្រូវបង់តម្លៃពេញក្នុងរយៈពេលដប់ថ្ងៃ។
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងត្រូវបន្តទូទាត់រហូតដល់តម្លៃតុល្យការបានបង់ពេញ។
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៦៖ ការបញ្ឈប់ការផ្តល់សេវាកម្ម
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            អាចបញ្ចប់កិច្ចព្រមព្រៀងនេះប្រសិនបើ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ខកខានក្នុងការបំពេញតម្រូវការរបស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ដែលបានសន្យា
                          </Typography>
                          <Typography className="contract-body-des">
                            និងបង្កភាពរអាក់រអួល
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬយឺតពេលក្នុងការប្រគល់ផលិតផលសម្រេចដោយមិនបានសុំការយល់ព្រម
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬ
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            គ្មានការយល់ព្រមពី
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងបង់ប្រាក់សំណងជូន
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ចំនួនទឹកប្រាក់ដែល
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            បាន
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ប្រគល់ជូនកន្លងមក។
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            អាចបញ្ចប់កិច្ចព្រមព្រៀងនេះ
                          </Typography>
                          <Typography className="contract-body-des">
                            ប្រសិនបើ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ខកខានក្នុងការបង់ថ្លៃសេវាតាមពេលកំណត់
                          </Typography>
                          <Typography className="contract-body-des">
                            និង
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            ផាកពិន័យទៅ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ចំនួនទឹកប្រាក់
                          </Typography>
                          <Typography className="contract-body-des">
                            ៥០%
                          </Typography>
                          <Typography className="contract-body-des">
                            បន្ថែមទៀត។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            និងបង់ប្រាក់សំណងដល់
                          </Typography>

                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ចំនួន៥០%
                          </Typography>
                          <Typography className="contract-body-des">
                            នៃតម្លៃសរុបសេវាកម្ម
                          </Typography>
                          <Typography className="contract-body-des">
                            សម្រាប់ការខូចខាតដែល
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            បណ្ដាលមកពីការបញ្ឈប់របស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”។
                          </Typography>
                        </Stack>

                        {/* ============================================== Third page ================================================ */}
                        {/* <table className="document-container-main"></table> */}
                        <Typography
                          className="contract-body-des-bold"
                          sx={{ paddingTop: "5px" }}
                        >
                          ប្រការ ៧៖ ការរក្សាការសម្ងាត់
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ភាគីទាំងពីរត្រូវយល់ព្រម
                          </Typography>
                          <Typography className="contract-body-des">
                            និងមានភាពហ្មត់ចត់ក្នុងការរក្សាទុករាល់ព័ត៌មានសម្ងាត់របស់ភាគីម្ខាងទៀត។
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des-bold">
                            “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ត្រូវគោរពថាមិនប្រើប្រាស់ការសម្ងាត់របស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ក្រៅពីសិទ្ធិផ្តល់ជូន
                            ឬតម្រូវការក្នុងកិច្ចព្រមព្រៀងឡើយ។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            អាចប្រើប្រាស់ព័ត៌មានសម្ងាត់របស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ក្នុងគោលបំណងផ្តល់សេវាកម្មជូនភាគីតែប៉ុណ្ណោះ។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគីទាំងពីរ
                          </Typography>
                          <Typography className="contract-body-des">
                            យល់ព្រមអនុវត្តគ្រប់គោលការណ៍
                          </Typography>
                          <Typography className="contract-body-des">
                            ដើម្បីចៀសវាងការប្រើប្រាស់
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬចែកចាយព័ត៌មានសម្ងាត់
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ដោយគ្មានការអនុញ្ញាតពីភាគីមួយទៀត។
                        </Typography>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៨៖ ការខូចខាត
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ខ្លឹមសារដែលផ្ទុយពីការផ្តល់ជូនក្នុងកិច្ចព្រមព្រៀងនេះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងមិនទទួលខុសត្រូវរាល់ការខូចខាតផ្សេងៗ
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            របស់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ទេ។
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            មិនមានទំនួលខុសត្រូវចំពោះការខូចខាតដែល
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            មានចំពោះការទាមទារតាមផ្លូវច្បាប់
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ឡើយ។
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            រាល់បញ្ហាដែលធ្វើឱ្យការផ្តល់ជូនសេវាកម្មយឺតយ៉ាវជាងពេលវេលាកំណត់
                          </Typography>
                          <Typography className="contract-body-des">
                            ដោយសារកំហុសបច្ចេកទេសរបស់
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងផ្តល់ថវិកាជាតម្លៃសរុបគិតជាឯកតាក្នុងដុល្លារអាមេរិក
                          </Typography>
                          <Typography className="contract-body-des">
                            ចាប់ពីថ្ងៃផុតកំណត់ទៅ
                          </Typography>
                          <Typography className="contract-body-des">
                            ដែលជា
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          តម្លៃតបស្នងការខូចខាត។
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ក្នុងលក្ខណ្ឌដែល
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            មិនអាចផ្តល់ព័ត៌មានត្រឡប់ក្នុងរយៈ
                          </Typography>
                          <Typography className="contract-body-des">
                            ៥ថ្ងៃក្នុងម៉ោងការងារ
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬមិនអាច
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ផ្តល់រាល់ព័ត៌មានដែលបានស្នើសុំពី
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            មានសិទ្ធិពន្យាពេល
                          </Typography>
                          <Typography className="contract-body-des">
                            ឬលើកថ្ងៃកំណត់បើសិនជាភាគី
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ត្រូវការ
                          </Typography>
                          <Typography className="contract-body-des">
                            និងចាំបាច់។{" "}
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ត្រូវផ្តល់ជូនថវិកាជាតម្លៃសរុបជាឯកតាក្នុងដុល្លារអាមេរិក
                          </Typography>
                          <Typography className="contract-body-des">
                            ចាប់ពីថ្ងៃផុតកំណត់ទៅ
                          </Typography>
                          <Typography className="contract-body-des">
                            ដែល
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            តម្លៃតបស្នងការខូចខាតជូនទៅ
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”។
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ៩៖ គ្រោះថ្នាក់មិនអាចគេចរួច
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ក្នុងករណីបង្ខំនូវកាលៈទេសៈសំខាន់ៗ
                          </Typography>
                          <Typography className="contract-body-des">
                            (គ្រោះមហន្តរាយធម្មជាតិ ប្រតិបត្តិការយោធា
                            ការបិទផ្លូវ)
                          </Typography>
                          <Typography className="contract-body-des">
                            ដែលធ្វើឱ្យ
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            រាំងស្ទះការបំពេញកិច្ចព្រមព្រៀង
                          </Typography>
                          <Typography className="contract-body-des">
                            នោះលក្ខខណ្ឌនៃការផ្តល់ជូនសេវាកម្មនេះត្រូវបានរក្សា
                          </Typography>
                          <Typography className="contract-body-des">
                            និងបន្តរហូតដល់កាលៈ
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ទេសៈទាំងនោះត្រូវបានបញ្ចប់រួចរាល់
                          </Typography>
                          <Typography className="contract-body-des">
                            បន្ទាប់មកយើងនឹងបន្តកិច្ចព្រមព្រៀងនេះជាមួយនឹង
                          </Typography>
                          <Typography className="contract-body-des">
                            រយៈពេលដែលនៅសល់។
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des-bold">
                          ប្រការ ១០៖ ប្រសិទ្ធភាព និងព្រំដែននៃកិច្ចព្រមព្រៀង
                        </Typography>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            className="contract-body-des"
                            style={{ paddingLeft: "44px" }}
                          >
                            កិច្ចព្រមព្រៀងនេះ
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងមានប្រសិទ្ធភាពរវាង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ទៅលើសេវាកម្មខាងលើ ។
                          </Typography>
                          <Typography className="contract-body-des">
                            ចំពោះការកែប្រែ
                          </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                          <Typography className="contract-body-des">
                            ការបន្ថែមអត្ថន័យផ្សេងៗ
                          </Typography>
                          <Typography className="contract-body-des">
                            នឹងទាមទារឱ្យភាគីទាំងពីរ
                          </Typography>
                          <Typography className="contract-body-des">
                            ធ្វើការពិភាក្សា
                          </Typography>
                          <Typography className="contract-body-des">
                            ព្រមព្រៀង
                          </Typography>
                          <Typography className="contract-body-des">
                            និងសរសេរជាលាយលក្ខណ៍អក្សរទៅ
                          </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1}>
                          <Typography className="contract-body-des">
                            លើកិច្ចព្រមព្រៀងបន្ថែមផ្សេងទៀត
                          </Typography>
                          <Typography className="contract-body-des">
                            និងត្រូវរក្សាទុក
                          </Typography>
                          <Typography className="contract-body-des">
                            ហើយភ្ជាប់ជាមួយកិច្ចព្រមព្រៀងនេះ។
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ paddingLeft: "44px" }}
                        >
                          <Typography className="contract-body-des">
                            ហត្ថលេខា
                          </Typography>
                          <Typography className="contract-body-des">
                            ខាងក្រោមបញ្ជាក់ថា
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            និង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            បានអានដោយយកចិត្តទុកដាក់
                          </Typography>
                          <Typography className="contract-body-des">
                            និងយល់ច្បាស់គ្រប់
                          </Typography>
                        </Stack>
                        <Typography className="contract-body-des">
                          ឃ្លា និងពាក្យពេចន៍នានា ដែលមាននៅក្នុងកិច្ចព្រមព្រៀងនេះ។
                        </Typography>
                        {/* ============================================== Fourth page ================================================ */}
                        {/* <table className="document-container-main"></table> */}
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingLeft: "44px", paddingTop: "50px" }}
                        >
                          <Typography className="contract-body-des">
                            កិច្ចព្រមព្រៀងនេះ
                          </Typography>
                          <Typography className="contract-body-des">
                            ធ្វើឡើងមានពីរច្បាប់សម្រាប់
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ក”
                          </Typography>
                          <Typography className="contract-body-des">
                            ០១ច្បាប់
                          </Typography>
                          <Typography className="contract-body-des">
                            និង
                          </Typography>
                          <Typography className="contract-body-des-bold">
                            ភាគី “ខ”
                          </Typography>
                          <Typography className="contract-body-des">
                            ០១ច្បាប់។
                          </Typography>
                        </Stack>

                        <Box sx={{ paddingTop: "80px" }}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Stack direction="column" spacing={12}>
                                <Typography
                                  className="contract-body-des-moul"
                                  sx={{ textAlign: "center" }}
                                >
                                  ក្រុមហ៊ុនហ្គោគ្លូប៊លអាយធី (ភាគី ក)
                                </Typography>
                                <Stack
                                  direction="column"
                                  spacing={1}
                                  sx={{ paddingLeft: "55px" }}
                                >
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      ឈ្មោះអ្នកតំណាង​
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {printData?.referenceName}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      មុខដំណែង
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {printData?.referencePosition}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      កាលបរិច្ឆេទ
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {getKhmerNumber(moment().format("DD"))}{" "}
                                      {getKhmerMonth(moment().format("M"))}{" "}
                                      {getKhmerNumber(moment().format("YYYY"))}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={6}>
                              <Stack direction="column" spacing={12}>
                                <Typography
                                  className="contract-body-des-moul"
                                  sx={{ textAlign: "center" }}
                                >
                                  {printData?.customer} (ភាគី ខ)
                                </Typography>
                                <Stack
                                  direction="column"
                                  spacing={1}
                                  sx={{ paddingLeft: "55px" }}
                                >
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      ឈ្មោះអ្នកតំណាង​
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {printData?.customerName}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      មុខដំណែង
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {printData?.customerPosition}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" width="100%">
                                    <Typography
                                      className="contract-body-des"
                                      width="110px"
                                    >
                                      កាលបរិច្ឆេទ
                                    </Typography>
                                    <Typography className="contract-body-des">
                                      ៖ {getKhmerNumber(moment().format("DD"))}{" "}
                                      {getKhmerMonth(moment().format("M"))}{" "}
                                      {getKhmerNumber(moment().format("YYYY"))}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>

        <DialogActions>
          <Stack direction="row" justifyContent="right" width="100%">
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle={`Contract-${printData?.title}`}
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
