import React, { useContext, useState } from "react";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { UPDATE_INVOICE_STATUS } from "../../Schema/Invoice";

export default function UpdateInvoiceStatus({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const { setAlert } = useContext(AuthContext);
  // console.log("editData::", editData);

  const [updatePaymentStatus] = useMutation(UPDATE_INVOICE_STATUS, {
    onCompleted: ({ updatePaymentStatus }) => {
      if (updatePaymentStatus?.status === true) {
        setAlert(true, "success", updatePaymentStatus?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", updatePaymentStatus?.message);
      }
    },
    onError: (error) => {
      console.log("Error", error?.message);
    },
  });

  const handleUpdateStatus = (status) => {
    updatePaymentStatus({
      variables: {
        id: editData?._id,
        status: status,
      },
    });
  };

  const dataStatus = [
    {
      label: "Unpaid",
      color: "orange",
    },
    {
      label: "Deposit",
      color: "#7C44F0",
    },
    {
      label: "Complete",
      color: "green",
    },
  ];

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              UPDATE INVOICE STATUS
            </Typography>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                defaultValue="female"
                onClick={(e) => console.log(e.target?.value)}
              >
                {dataStatus.map((item) => (
                  <FormControlLabel
                    key={item?.label}
                    value={item?.label}
                    checked={item?.label === editData?.payment ? true : false}
                    control={<Radio style={{ color: item.color }} />}
                    onChange={(e) => handleUpdateStatus(item?.label)}
                    label={
                      <span
                        style={{
                          color: item.color,
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.label}
                      </span>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
